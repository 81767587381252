import React, { useState } from 'react'
import SideBarLink from './SideBarLink'
import logo from '../assets/imges/Logo.png'
import { Link } from 'react-router-dom'
import { FiMenu } from "react-icons/fi";
import { IoCloseCircleSharp } from "react-icons/io5";
import { useSelector } from 'react-redux';

const SideBar = () => {
    const [value, setValue] = useState('')
    const handleClick = () => {
        setValue(!value)
    }
    const { isLoading, userDetail } = useSelector((state) => state.user)

    return (
        <>
            <div className="sidebar sidebar-desktop  ps-3 " style={{ width: '18%' }}>
                <div className='h-screen overflow-y-scroll pe-3'>

                    <SideBarLink
                        plan={
                            ['price_1QV39FEhsh9AaJCvODSIlKlZ', 'price_1QV39FEhsh9AaJCvH07TeW0b'].includes(userDetail?.subscription_information?.stripe_price_id) ? 'starter' :
                                ['price_1QV39IEhsh9AaJCv6v2V5Ns0', 'price_1QV39IEhsh9AaJCvG5qSRm9n'].includes(userDetail?.subscription_information?.stripe_price_id) ? 'essential' :
                                    ['price_1QV39KEhsh9AaJCvapLd8zqi', 'price_1QV39KEhsh9AaJCvheykDdJG'].includes(userDetail?.subscription_information?.stripe_price_id) ? 'growth' : 'unknown'
                        }
                    />
                </div>
            </div>
            <div className="mbl-nav" style={{ borderBottom: '2px solid #F7F7F7' }}>
                <div className="container-fluid">
                    <div className="row" >
                        <div className="col-6">

                            <Link
                                to="/">
                                <img src={logo} width="160px" alt />
                            </Link>
                        </div>
                        <div className="col-6 text-end flex items-center justify-end">
                            {!value &&
                                <button className='h-auto btn-blue rounded-5 p-2' onClick={handleClick} type='buttom'>
                                    <FiMenu />
                                </button>}
                        </div>
                    </div>
                </div>
            </div>
            <div className={`sidebar mbl-nav ${value ? 'sidebar-mobile-if' : 'sidebar-mobile-else'} ps-2 pe-0`} >
                {value && <div>
                    {/* <div className='d-flex justify-content-between  p-2'> */}
                    {/* <img src={logo} width="100px" alt /> */}

                    <span onClick={handleClick} role='buttom' className='absolute right-1 top-1'><IoCloseCircleSharp className='blue' size={30} /></span>
                    {/* </div> */}
                    <SideBarLink
                        plan={
                            ['price_1QV39FEhsh9AaJCvODSIlKlZ', 'price_1QV39FEhsh9AaJCvH07TeW0b'].includes(userDetail?.subscription_information?.stripe_price_id) ? 'starter' :
                                ['price_1QV39IEhsh9AaJCv6v2V5Ns0', 'price_1QV39IEhsh9AaJCvG5qSRm9n'].includes(userDetail?.subscription_information?.stripe_price_id) ? 'essential' :
                                    ['price_1QV39KEhsh9AaJCvapLd8zqi', 'price_1QV39KEhsh9AaJCvheykDdJG'].includes(userDetail?.subscription_information?.stripe_price_id) ? 'growth' : 'unknown'
                        }
                    />
                </div>}
            </div>

        </>
    )
}

export default SideBar
