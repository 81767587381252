import React, { useEffect, useState } from 'react'
import FullScreenLoader from '../../components/loaders/FullScreenLoader';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerSecret, getUser } from '../../redux/slices/userSlice';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Modal } from "react-bootstrap";
import ComparePaymentTable from "../../components/ComparePaymentTable";
import { Button } from 'reactstrap';
import { useToast } from '../../components/toast/ToastContext';


export default function NewStripe() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [customerSecret, setCustomerSecret] = useState();
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        setTimeout(function () {
            getUserNewData()
        }, 5000)
    }, [])

    async function getUserNewData() {
        const accessToken = localStorage.getItem('userToken')
        const response = await axios.get('https://api.timelink.au/api/stripe/customer/subscription/details', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        });
        console.log(JSON.stringify(response.data.data) + '====data fetched directly');
        const userData = response.data.data;
        if (response?.data?.hasActiveSubscription) {
            navigate('/');
            dispatch(getUser());
        } else {
            dispatch(getCustomerSecret(handleResponse))
        }

    }

    function handleResponse(data) {
        setCustomerSecret(data);
        setIsLoading(false)
    }
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true)
    };
    return isLoading ? (
        <FullScreenLoader isLoading={isLoading} verifyingPayment={true} />
    ) : (
        <>
            <div className="w-screen h-full flex flex-col justify-center items-center pb-16 bg-white">
                <div className="w-full px-3 py-1 flex justify-between">
                    <button
                        type="button"
                        className="px-3 invisible	 font-semibold"
                    >
                        Logout
                    </button>

                    <div className="invisible" alt="w8" ></div>

                    <button
                        onClick={() => {
                            localStorage.clear();
                            navigate('/')
                        }}
                        type="button"
                        className="px-3 p-2  border-0 rounded-lg text-black font-semibold"
                    >
                        Logout
                    </button>

                </div>
                <div className=''>
                    <h1 className='font-semibold text-2xl text-center my-1'>Your account has been approved!</h1>
                    <p className='font-medium text-lg text-center mt-1 mb-2'>Please select the subscription you would like to purchase.</p>
                </div>
                <div className="p-4 bg-white w-4/5 shadow-lg rounded-[59px]">

                    <div className='flex justify-center items-center'>
                        <Button
                            onClick={handleShow}
                            type="submit"
                            className="btn-blueTwo rounded-3 inter my-2 !p-2.5 w-1/4 fw-semibold text-sm !normal-case"
                            sx={{ fontFamily: "Inter, sans-serif !important" }}
                        >
                            Compare Prices
                        </Button>
                    </div>
                    <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
                    <stripe-pricing-table
                        pricing-table-id="prctbl_1QV3R4Ehsh9AaJCvz9POp5JK"
                        publishable-key="pk_live_51N3dH4Ehsh9AaJCvwJcmLbddavBMsZowVeTTXHYa3UrTCsZrTQ9HxwEILe9tDu5gHVAHzVripYSvhrtZoYsMSWui00emDpL7fl"
                        customer-session-client-secret={customerSecret}>

                    </stripe-pricing-table>
                </div>
            </div>


            <Modal size="xl" show={show} onHide={handleClose} className="ps-0">

                <Modal.Body className="ps-0">
                    <ComparePaymentTable closeModal={handleClose} />
                </Modal.Body>

            </Modal>
        </>
    )
}
