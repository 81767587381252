import React, { useRef } from "react";
import { useEffect, useState } from "react";
import logo from "../assets/imges/Logo.png";
import { MdMoving } from "react-icons/md";
import { Link, NavLink } from "react-router-dom";
import { BsFileEarmarkCode, BsFileText } from "react-icons/bs";
import { RiSurveyLine } from "react-icons/ri";
import { TbDevices } from "react-icons/tb";
import { GrAnnounce } from "react-icons/gr";
// import { Tour } from "@frigade/react";
const SideBarLink = ({ plan }) => {
  const [pathName, setPathName] = useState("");
  const frigadeTriggerRef = useRef(null);

  useEffect(() => {
    const newPathName = window.location.pathname;
    setPathName(newPathName);
  }, []);
  // useEffect(() => {
  //   if (frigadeTriggerRef.current) {
  //     frigadeTriggerRef.current.click();
  //   }
  // }, []);
  return (
    <>

      {/* <div id="order-frigade-1" ref={frigadeTriggerRef} style={{position:"absolute", top:'20%',left:'10%'}}>

        </div> */}
      {/* <Tour flowId="flow_AdXy7YxF" tooltipPosition="auto" zIndex={9999} />
        <div id="order-frigade-2" ref={frigadeTriggerRef} style={{position:"absolute", top:'20%',left:'10%'}}></div> */}
      <div className="mb-4 text-cente ps-2 pt-4">
        <Link to="/">
          <img src={logo} width="120" alt="w8" />
        </Link>
      </div>
      <ul className="ps-0 nunito">
        {/* <li className="my-3">
                    <NavLink to='/' className="d-flex flex-row align-items-center rounded-3  ">

                        <i className="fa-solid fa-users" /><span className="font-15 fw-bold">
                            Community </span>


                    </NavLink>
                </li> */}
        {
          //starter plan links
          plan === "starter" ? (
            <>
              <li className="my-3">
                <NavLink
                  to="/"
                  className={` d-flex flex-row align-items-center rounded-3  ${pathName == "/order/stats/new" ? "active" : ""
                    }`}
                >
                  <BsFileText size={24} className="me-2" />
                  <span className="font-15 fw-bold">Orders </span>
                </NavLink>
              </li>
              <li className="my-3 cursor-not-allowed disabled-link">
                <div
                  className={` d-flex flex-row align-items-center rounded-3 disabled-link `}
                >
                  <GrAnnounce size={24} className="me-2" />
                  <span className="font-15 fw-bold">Marketing </span>
                </div>
              </li>
              <li className="my-3">
                <NavLink
                  to="/device/new"
                  className="d-flex flex-row align-items-center rounded-3  "
                >
                  <TbDevices size={24} className="me-2" />
                  <span className="font-15 fw-bold">Linked Devices </span>
                </NavLink>
              </li>
              <li className="my-3 cursor-not-allowed disabled-link">
                <div
                  className={` d-flex flex-row align-items-center rounded-3 disabled-link `}
                >
                  <RiSurveyLine size={24} className="me-2" />
                  <span className="font-15 fw-bold">Surveys </span>
                </div>
              </li>
              <li className="my-3">
                <NavLink
                  to="/integrations"
                  className={`d-flex flex-row align-items-center rounded-3 ${pathName == "/integrations/detail/:id" && "active"
                    }`}
                >
                  <BsFileEarmarkCode size={24} className="me-2" />
                  <span className="font-15 fw-bold">Integrations </span>
                </NavLink>
              </li>
              <li className="my-3">
                <NavLink
                  to="/setting"
                  className="d-flex flex-row align-items-center rounded-3 "
                >
                  <i className="fa-solid fa-gear" />
                  <span className="font-15 fw-bold">Setting</span>
                </NavLink>
              </li>

            </>
          ) : plan === "essential" ? (
            <>
              <li className="my-3">
                <NavLink
                  to="/"
                  className={` d-flex flex-row align-items-center rounded-3  ${pathName == "/order/stats/new" ? "active" : ""
                    }`}
                >
                  <BsFileText size={24} className="me-2" />
                  <span className="font-15 fw-bold">Orders </span>
                </NavLink>
              </li>
              {/* <li className="my-3">
                                    <NavLink to="/loyalty/new" className={` d-flex flex-row align-items-center rounded-3  ${pathName == '/loyalty/detail/new' ? 'active' : ''}`}><i className="fa-solid fa-tag" /><span className="font-15 fw-bold">
                                        Loyalty </span></NavLink>
                                </li> */}
              <li className="my-3 cursor-not-allowed disabled-link">
                <div className={` d-flex flex-row align-items-center rounded-3 disabled-link `}>
                  <GrAnnounce size={24} className="me-2" />
                  <span className="font-15 fw-bold"> Marketing </span>
                </div>
              </li>
              <li className="my-3">
                <NavLink
                  to="/device/new"
                  className="d-flex flex-row align-items-center rounded-3  "
                >
                  <TbDevices size={24} className="me-2" />
                  <span className="font-15 fw-bold">Linked Devices </span>
                </NavLink>
              </li>
              <li className="my-3 cursor-not-allowed disabled-link">
                <div
                  className={` d-flex flex-row align-items-center rounded-3 disabled-link `}
                >
                  <RiSurveyLine size={24} className="me-2" />
                  <span className="font-15 fw-bold">Surveys </span>
                </div>
              </li>
              <li className="my-3">
                <NavLink
                  to="/integrations"
                  className={`d-flex flex-row align-items-center rounded-3 ${pathName == "/integrations/detail/:id" && "active"
                    }`}
                >
                  <BsFileEarmarkCode size={24} className="me-2" />
                  <span className="font-15 fw-bold">Integrations </span>
                </NavLink>
              </li>
              <li className="my-3">
                <NavLink
                  to="/setting"
                  className="d-flex flex-row align-items-center rounded-3 "
                >
                  <i className="fa-solid fa-gear" />
                  <span className="font-15 fw-bold">Setting</span>
                </NavLink>
              </li>

            </>
          ) : plan === "growth" ? (
            <>
              <li className="my-3">
                <NavLink
                  to="/"
                  className={` d-flex flex-row align-items-center rounded-3  ${pathName == "/order/stats/new" ? "active" : ""
                    }`}
                >
                  <BsFileText size={24} className="me-2" />
                  <span className="font-15 fw-bold">Orders </span>
                </NavLink>
              </li>
              <li className="my-3">
                <NavLink
                  to="/marketing/campaigns/new"
                  className={` d-flex flex-row align-items-center rounded-3  ${pathName == "/marketing/campaigns/detail/new"
                      ? "active"
                      : ""
                    }`}
                >
                  <GrAnnounce size={24} className="me-2" />
                  <span className="font-15 fw-bold">Marketing </span>
                </NavLink>
              </li>
              {/* <li className="my-3">
                                        <NavLink to="/loyalty/new" className={` d-flex flex-row align-items-center rounded-3  ${pathName == '/loyalty/detail/new' ? 'active' : ''}`}><i className="fa-solid fa-tag" /><span className="font-15 fw-bold">
                                            Loyalty </span></NavLink>
                                    </li> */}
              {/* <li className="my-3">
                                        <NavLink to="/rewards" className={` d-flex flex-row align-items-center rounded-3  ${pathName == '/rewards' ? 'active' : ''}`}><i class="fa-solid fa-award" /><span className="font-15 fw-bold">
                                            Rewards </span></NavLink>
                                    </li> */}
              <li className="my-3">
                <NavLink
                  to="/device/new"
                  className="d-flex flex-row align-items-center rounded-3  "
                >
                  <TbDevices size={24} className="me-2" />
                  <span className="font-15 fw-bold">Linked Devices </span>
                </NavLink>
              </li>
              <li className="my-3">
                <NavLink
                  to="/surveys"
                  className={` d-flex flex-row align-items-center rounded-3  ${pathName == "/surveyDetail" ? "active" : ""
                    }`}
                >
                  <RiSurveyLine size={24} className="me-2" />
                  <span className="font-15 fw-bold">Surveys</span>
                </NavLink>
              </li>
              {/* <li className="my-3">
                                        <NavLink to='/helpcenter' className="d-flex flex-row align-items-center rounded-3"><i className="fa-solid fa-helicopter-symbol" /><span className="font-15 fw-bold">
                                            Help Centre</span></NavLink>
                                    </li>
                                    <li className="my-3">
                                        <NavLink to='/subscription' className="d-flex flex-row align-items-center rounded-3 "><i class="fa-solid fa-credit-card"></i><span className="font-15 fw-bold">
                                            Subscription </span></NavLink>
                                    </li> */}
              <li className="my-3">
                <NavLink
                  to="/integrations"
                  className={`d-flex flex-row align-items-center rounded-3 ${pathName == "/integrations/detail/:id" && "active"
                    }`}
                >
                  <BsFileEarmarkCode size={24} className="me-2" />
                  <span className="font-15 fw-bold">Integrations </span>
                </NavLink>
              </li>
              <li className="my-3">
                <NavLink
                  to="/setting"
                  className="d-flex flex-row align-items-center rounded-3 "
                >
                  <i className="fa-solid fa-gear" />
                  <span className="font-15 fw-bold">Setting</span>
                </NavLink>
              </li>

            </>
          ) : (
            <>
              <li className="my-3 cursor-not-allowed disabled-link">
                <div
                  className={` d-flex flex-row align-items-center rounded-3 disabled-link `}
                >
                  <BsFileText size={24} className="me-2" />
                  <span className="font-15 fw-bold">Orders </span>
                </div>
              </li>
              <li className="my-3 cursor-not-allowed disabled-link">
                <div
                  className={` d-flex flex-row align-items-center rounded-3 disabled-link `}
                >
                  <GrAnnounce size={24} className="me-2" />
                  <span className="font-15 fw-bold">Marketing </span>
                </div>
              </li>
              <li className="my-3 cursor-not-allowed disabled-link">
                <div
                  className={` d-flex flex-row align-items-center rounded-3 disabled-link `}
                >
                  <TbDevices size={24} className="me-2" />
                  <span className="font-15 fw-bold">Linked Devices </span>
                </div>
              </li>
              <li className="my-3 cursor-not-allowed disabled-link">
                <div
                  className={` d-flex flex-row align-items-center rounded-3 disabled-link `}
                >
                  <RiSurveyLine size={24} className="me-2" />
                  <span className="font-15 fw-bold">Surveys </span>
                </div>
              </li>
              <li className="my-3 cursor-not-allowed disabled-link">
                <div
                  className={` d-flex flex-row align-items-center rounded-3 disabled-link `}
                >
                  {" "}
                  <BsFileEarmarkCode size={24} className="me-2" />
                  <span className="font-15 fw-bold">Integrations </span>
                </div>
              </li>
              <li className="my-3">
                <NavLink
                  to="/setting"
                  className="d-flex flex-row align-items-center rounded-3 "
                >
                  <i className="fa-solid fa-gear" />
                  <span className="font-15 fw-bold">Setting</span>
                </NavLink>
              </li>

            </>
          )
        }
      </ul>
    </>
  );
};

export default SideBarLink;
