import React, { useEffect, useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { Modal } from 'react-bootstrap';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { FaArrowLeft, FaArrowRotateLeft, FaArrowRotateRight } from 'react-icons/fa6';
import { FiZoomIn, FiZoomOut } from 'react-icons/fi';
import { GrGallery } from 'react-icons/gr';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Col, Container, Row } from 'reactstrap';
import upload from '../../assets/imges/upload.png';
import { seTgetErrorNull, updateBusinessProfile } from '../../redux/slices/userSlice';
import '../../style/businesLogin.scss';
import FullScreenLoader from '../../components/loaders/FullScreenLoader';

const WellcomeFour = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLoading, getError, userDetail } = useSelector((state) => state.user)
    //   ---------- image crop ----------
    const [file, setFile] = useState(null);
    const [editImgModal, setEditImgModal] = useState(false);
    const [image, setImage] = useState(null)
    const [scale, setScale] = useState(1)
    const [rotate, setRotate] = useState(0)
    const [cnvsImage, setCnvsImage] = useState("")
    const [businessLogo, setBusinessLogo] = useState("");
    const previewRef = useRef();
    useEffect(() => {
        if (image) {
            console.log(image.name, ' this is selected image')
        }
    }, [image])

    useEffect(() => {
        if (Object.keys(userDetail).length > 0) {
            if (!userDetail?.phone) {
                navigate("/onboarding/number");
            } else if (!userDetail?.is_phone_verified) {
                navigate("/onboarding/number/verify");
            } else if (!userDetail?.business_name) {
                navigate("/profile/set");
            } else if (!userDetail?.abn) {
                navigate("/profile/abn");
            } else {

            }
        }
    }, [userDetail]);
    const handlePreview = () => {
        if (previewRef.current) {
            const cnvs = previewRef.current.getImage();
            console.log('canvas get image ', cnvs)
            cnvs.toBlob((blob) => {
                setFile(blob);

                console.log(typeof (blob), "------------blob image")
                const previousImg = URL.createObjectURL(blob);
                setCnvsImage(previousImg);
            });
            setEditImgModal(false)
            // setImage('');
            console.log(cnvsImage, "-----final")
        }
    };
    // ======== cover image upload
    //   ---------- image crop ----------
    const [coverfile, setCoverFile] = useState(null);
    const [coverEditImgModal, setCoverEditImgModal] = useState(false);
    const [coverImage, setCoverImage] = useState()
    const [coverScale, setCoverScale] = useState(1)
    const [coverRotate, setCoverRotate] = useState(0)
    const [coverCnvsImage, setCoverCnvsImage] = useState("")
    const [coverBusinessLogo, setCoverBusinessLogo] = useState("");
    const coverPreviewRef = useRef();
    const handleCoverPreview = () => {
        if (coverPreviewRef.current) {
            const cnvs = coverPreviewRef.current.getImage();
            console.log('canvas get image ', cnvs)
            cnvs.toBlob((blob) => {
                setCoverFile(blob);

                console.log(typeof (blob), "------------blob image")
                const previousImg = URL.createObjectURL(blob);
                setCoverCnvsImage(previousImg);
            });
            setCoverEditImgModal(false)
            // setCoverImage('');
            console.log(coverCnvsImage, "-----final")
        }
    };
    // =====
    useEffect(() => {
        if (getError && getError.length > 1) {
            toast.error(getError, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            dispatch(seTgetErrorNull());
        }
    }, [getError])

    function handleSubmit(e) {

        if (!file) {
            toast.error('Main logo is required!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return
        }
        e.preventDefault();
        var data = new FormData();
        file && data.append('images', file)
        coverfile && data.append('cover_images', coverfile)
        dispatch(updateBusinessProfile(data, () => {
            navigate('/profile/category')
        }))

    }
    return (
        <>
            {
                isLoading &&
                <FullScreenLoader isLoading={isLoading} />
            }
            <Container fluid className=' inter' >
                <Row className='min-h-screen flex flex-col ' style={{ backgroundColor: '#ffffff' }}>

                    <div className=' p-3 d-flex flex-row justify-content-between' >
                        <Link to="/profile/abn" className='mt-3 no-underline'>
                            <button type='button' className='px-3 p-2 flex gap-2 items-center  border-0 rounded-lg text-black font-semibold'><FaArrowLeft />Back</button>
                        </Link>
                        {
                            <></>
                            //cnvsImage && cnvsImage?.length &&
                            //{/* <img src={cnvsImage} width={55} height={40} className="profile mx-2 rounded-circle mr-1 " alt='' /> */ }
                        }
                        <button
                            onClick={() => {
                                localStorage.clear();
                                navigate('/')
                            }}
                            type="button"
                            className="px-3 p-2  border-0 rounded-lg text-black font-semibold"
                        >
                            Logout
                        </button>
                    </div>

                    <Col lg={7} md={12} sm={12} xs={12} className='align-items-center justify-content-center d-flex  my-2 mx-auto !grow' >
                        <Container fluid >
                            <Row className='justify-content-center'>
                                <Col lg={9} md={9} sm={12} xs={12} >
                                    <div>
                                        <div className='inter '>
                                            <p className="font-14 font-semibold text-black  mb-1">TimeLink | Start building your business profile</p>
                                            <h3 className=" fw-bold  text-black ">Upload your business logos </h3>



                                        </div>
                                        <div className=' mt-5'>
                                            <div className='shadow'>
                                                <div className="flex justify-center">

                                                    <div className="bg-white  d-flex justify-content-center rounded-5 p-3 !w-fit">

                                                        <ul className="nav nav-pills bg-gray-100 !p-1.5 ps-1.5 rounded-5 d-inline-flex" id="pills-tab" role="tablist">
                                                            <li className="nav-item" role="presentation">
                                                                <button className="nav-link active rounded-5 fw-bold" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Main Logo</button>
                                                            </li>
                                                            <li className="nav-item" role="presentation">
                                                                <button className="nav-link rounded-5 fw-bold" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Cover Image</button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="tab-content " id="pills-tabContent">
                                                    <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex={0}>
                                                        {!cnvsImage && <div className='compaign-detail-tab-img p-4' onClick={() => setEditImgModal(true)} style={{ borderBottomLeftRadius: "2rem", borderBottomRightRadius: "2rem" }}>
                                                            <div className='p-5  text-center rounded-5 ' style={{ border: '2px dashed #E2E6EA' }}>
                                                                <p className='mb-0 text-dark-gray'>Click to browse or <br /> drag and drop your files</p>
                                                            </div>
                                                        </div>}
                                                        {cnvsImage &&
                                                            <div className="p-3 bg-white rounded-2xl ">

                                                                <div className='' style={{ borderTop: "1.5px solid #EBEFF2" }}>
                                                                    <div className='  d-flex justify-content-between align-items-center p-2' style={{ borderBottom: "1.5px solid #EBEFF2" }}>

                                                                        <div className='d-flex '>

                                                                            <div>
                                                                                <GrGallery className='text-blue ' />
                                                                            </div>
                                                                            <div className='ms-2'>
                                                                                <p className='font-14 mb-0 fw-medium'>{image && image.name}</p>
                                                                                <p className='font-12 mb-0'>Just now</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className='flex  gap-1 items-center'>
                                                                            <span className='p-2 rounded-2 font-12 fw-semibold ' style={{ border: "1px solid #EBEFF2" }}>
                                                                                {(image && image?.size / 1024).toFixed(2)}KB
                                                                            </span>
                                                                            <BsThreeDotsVertical />
                                                                        </div>
                                                                    </div>
                                                                    {/* <div className='  d-flex justify-content-between align-items-center p-2' style={{ borderBottom: "1.5px solid #EBEFF2" }}>

                                                                        <div className='d-flex '>

                                                                            <div>
                                                                                <GrGallery className='text-blue ' />
                                                                            </div>
                                                                            <div className='ms-2'>
                                                                                <p className='font-14 mb-0 fw-medium'>marketing-campaign-test-3.png</p>
                                                                                <p className='font-12 mb-0'>Just now</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className='flex  gap-1 items-center'>
                                                                            <span className='p-2 rounded-2 font-12 fw-semibold ' style={{ border: "1px solid #EBEFF2" }}>
                                                                                929KB
                                                                            </span>
                                                                            <BsThreeDotsVertical />
                                                                        </div>
                                                                    </div> */}

                                                                </div>
                                                                <div className='text-center'>

                                                                    <button type='button' onClick={() => setEditImgModal(true)} className='px-3 p-2 mt-3 border-[1.5px] rounded-full text-black'>Edit</button>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="tab-pane fade " id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab " tabIndex={0}>
                                                        {!coverCnvsImage && <div className='compaign-detail-tab-img p-4' onClick={() => setCoverEditImgModal(true)} style={{ borderBottomLeftRadius: "2rem", borderBottomRightRadius: "2rem" }}>
                                                            <div className='p-5  text-center rounded-5 ' style={{ border: '2px dashed #E2E6EA' }}>
                                                                <p className='mb-0 text-dark-gray'>Click to browse or <br /> drag and drop your files</p>
                                                            </div>
                                                        </div>}
                                                        {coverCnvsImage && <div className="p-3 bg-white rounded-2xl ">

                                                            <div className='' style={{ borderTop: "1.5px solid #EBEFF2" }}>
                                                                <div className='  d-flex justify-content-between align-items-center p-2' style={{ borderBottom: "1.5px solid #EBEFF2" }}>

                                                                    <div className='d-flex '>

                                                                        <div>
                                                                            <GrGallery className='text-blue ' />
                                                                        </div>
                                                                        <div className='ms-2'>
                                                                            <p className='font-14 mb-0 fw-medium'>{coverImage && coverImage.name}</p>
                                                                            <p className='font-12 mb-0'>Just now</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className='flex  gap-1 items-center'>
                                                                        <span className='p-2 rounded-2 font-12 fw-semibold ' style={{ border: "1px solid #EBEFF2" }}>
                                                                            {coverImage && (coverImage.size / 1024).toFixed(2)}KB
                                                                        </span>
                                                                        <BsThreeDotsVertical />
                                                                    </div>
                                                                </div>
                                                                {/* <div className='  d-flex justify-content-between align-items-center p-2' style={{ borderBottom: "1.5px solid #EBEFF2" }}>

                                                                    <div className='d-flex '>

                                                                        <div>
                                                                            <GrGallery className='text-blue ' />
                                                                        </div>
                                                                        <div className='ms-2'>
                                                                            <p className='font-14 mb-0 fw-medium'>marketing-campaign-test-3.png</p>
                                                                            <p className='font-12 mb-0'>Just now</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className='flex  gap-1 items-center'>
                                                                        <span className='p-2 rounded-2 font-12 fw-semibold ' style={{ border: "1px solid #EBEFF2" }}>
                                                                            929KB
                                                                        </span>
                                                                        <BsThreeDotsVertical />
                                                                    </div>
                                                                </div> */}

                                                            </div>
                                                            <div className='text-center'>

                                                                <button type='button' onClick={() => setCoverEditImgModal(true)} className='px-3 p-2 mt-4 border-[1.5px] rounded-full text-black'>Edit</button>
                                                            </div>
                                                        </div>}

                                                    </div>
                                                </div>
                                            </div>
                                            <button type='button' onClick={handleSubmit} className='px-3 p-2 mt-4 border-[1.5px] rounded-lg text-black'>Continue</button>
                                        </div>
                                    </div>


                                </Col>
                            </Row>
                        </Container>
                    </Col>

                </Row>
            </Container>
            {/* ===== modle one  */}
            <Modal show={editImgModal} className='business_admin_main' centered onHide={() => setEditImgModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Upload Image</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {image && (
                            <>
                                <div className="imag_crop_shadow d-flex justify-content-center ">

                                    <AvatarEditor
                                        width={150}
                                        height={150}
                                        ref={previewRef}
                                        border={50}
                                        borderRadius={14}
                                        color={[80, 76, 76, 0.6]}
                                        scale={scale}
                                        rotate={rotate}
                                        image={URL.createObjectURL(image)}
                                    />
                                </div>
                                <div className='mt-2'>
                                    <button role='button' className='btn-blue rounded-2 p-1 m-1' onClick={() => setScale(scale - 0.1)}><FiZoomOut /></button>
                                    <button role='button' className='btn-blue rounded-2 p-1 m-1' onClick={() => setScale(scale + 0.1)}><FiZoomIn /></button>

                                    <button role='button' className='btn-blue rounded-2 p-1 m-1' onClick={() => setRotate(rotate - 90)}><FaArrowRotateLeft /></button>
                                    <button role='button' className='btn-blue rounded-2 p-1 m-1' onClick={() => setRotate(rotate + 90)}><FaArrowRotateRight /></button>
                                </div>
                            </>
                        )}

                        <div className=" inputgroup1 p-3 mt-1 text-center ">

                            <label htmlFor="file" className='h-100 w-100 cursor-pointer' >
                                <div className="px-3 py-3 mb-3 ">
                                    <div className="flex justify-center">

                                        <img src={upload} width={60} alt="w8" />
                                    </div>
                                    <h6 className="font-15 font-500 inter colorblack mt-2 point">Drag &amp; drop
                                        files or <span className="colorblue text-decoration-underline">Browse</span>
                                    </h6>
                                    <p className="mb-0 mt-2 font-12 colorgray inter point">Supported
                                        formates: <br />
                                        JPEG, PNG</p>
                                </div>
                            </label>
                        </div>
                        <input type="file" id='file' onChange={(e) => setImage(e.target.files[0])} className="d-none" accept="image/*" />
                    </div>
                </Modal.Body>
                {image && <Modal.Footer>
                    <button role='button' className='btn-blue rounded-2 p-2 m-1' onClick={handlePreview}>Upload</button>

                </Modal.Footer>}
            </Modal>
            {/* === cover image modle */}
            <Modal show={coverEditImgModal} className='business_admin_main' centered onHide={() => setCoverEditImgModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Upload Image</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {coverImage && (
                            <>
                                <div className="imag_crop_shadow d-flex justify-content-center ">

                                    <AvatarEditor
                                        width={310}
                                        height={120}
                                        ref={coverPreviewRef}
                                        border={40}
                                        borderRadius={14}
                                        color={[80, 76, 76, 0.6]}
                                        scale={coverScale}
                                        rotate={coverRotate}
                                        image={URL.createObjectURL(coverImage)}
                                    />
                                </div>
                                <div className='mt-2'>
                                    <button role='button' className='btn-blue rounded-2 p-1 m-1' onClick={() => setCoverScale(coverScale - 0.1)}><FiZoomOut /></button>
                                    <button role='button' className='btn-blue rounded-2 p-1 m-1' onClick={() => setCoverScale(coverScale + 0.1)}><FiZoomIn /></button>

                                    <button role='button' className='btn-blue rounded-2 p-1 m-1' onClick={() => setCoverRotate(coverRotate - 90)}><FaArrowRotateLeft /></button>
                                    <button role='button' className='btn-blue rounded-2 p-1 m-1' onClick={() => setCoverRotate(coverRotate + 90)}><FaArrowRotateRight /></button>
                                </div>
                            </>
                        )}

                        <div className=" inputgroup1 p-3 mt-1 text-center ">

                            <label htmlFor="file-cover" className='h-100 w-100 cursor-pointer' >
                                <div className="px-3 py-3 mb-3 ">
                                    <div className="flex justify-center">

                                        <img src={upload} width={60} alt="w8" />
                                    </div>
                                    <h6 className="font-15 font-500 inter colorblack mt-2 point">Drag &amp; drop
                                        files or <span className="colorblue text-decoration-underline">Browse</span>
                                    </h6>
                                    <p className="mb-0 mt-2 font-12 colorgray inter point">Supported
                                        formates: <br />
                                        JPEG, PNG</p>
                                </div>
                            </label>
                        </div>
                        <input type="file" id='file-cover' onChange={(e) => setCoverImage(e.target.files[0])} className="d-none" accept="image/*" />
                    </div>
                </Modal.Body>
                {coverImage && <Modal.Footer>
                    <button role='button' className='btn-blue rounded-2 p-2 m-1' onClick={handleCoverPreview}>Upload</button>

                </Modal.Footer>}
            </Modal>
            <ToastContainer />
        </>
    );
}

export default WellcomeFour;
