/* eslint-disable eqeqeq */
import React, { useState } from "react";
import moment from "moment";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/zoom.css";
import { Modal, Spinner } from "react-bootstrap";
import {
  FormControl,
  Select,
  MenuItem,
  Button
} from "@mui/material";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaCheck, FaCrown, FaUnlock } from "react-icons/fa";
import { FiDownload } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { impersonationLogin, updateBusiness, updateBusinessSubscription } from "../../redux/slices/userSlice";
import { dispatch } from "../../redux/store/store";
import { FaDeleteLeft, FaLock, FaTrash } from "react-icons/fa6";
import Swal from "sweetalert2";
const essentialPlan = ['price_1QV39FEhsh9AaJCvODSIlKlZ', 'price_1QV39FEhsh9AaJCvH07TeW0b'];
const growthPlan = ['price_1QV39IEhsh9AaJCv6v2V5Ns0', 'price_1QV39IEhsh9AaJCvG5qSRm9n'];
const elitePlan = ['price_1QV39KEhsh9AaJCvapLd8zqi', 'price_1QV39KEhsh9AaJCvheykDdJG'];
export default function BusinessItem({ currentItems }) {
  const Navigate = useNavigate();
  const disptach = useDispatch();
  const { isLoading } = useSelector(state => state.user)
  const [subscriptionModal, setSubscriptionModal] = useState(false)
  const [selectedSubscriptionStatus, setSelectedSubscriptionStatus] = useState(null)
  const [selectedPriceId, setSelectedPriceId] = useState(null)
  const [currentBusinessId, setCurrentBusinessId] = useState(null)
  function handleSetAppoved(id) {
    var data = {
      is_approved: 1,
    };
    dispatch(updateBusiness(id, data));
  }
  function handleSetDelete(id) {
    Swal.fire({
      title: "Are you sure want to delete this business?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1B4FF8",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
        var data = {
          is_deleted: 1,
        };
        dispatch(updateBusiness(id, data));
      }
    });

  }
  function handleSetLock(id) {
    Swal.fire({
      title: "Are you sure want to lock this business?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1B4FF8",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, lock it!"
    }).then((result) => {
      if (result.isConfirmed) {
        var data = {
          is_profile_locked: 1,
        };
        dispatch(updateBusiness(id, data));
      }
    });

  }

  function handleSetUnLock(id) {
    Swal.fire({
      title: "Are you sure want to unlock this business?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1B4FF8",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, unlock it!"
    }).then((result) => {
      if (result.isConfirmed) {
        var data = {
          is_profile_locked: 0,
        };
        dispatch(updateBusiness(id, data));
      }
    });

  }

  function handleOpenSubscriptionModal(data) {
    setSelectedPriceId(data?.subscription_information?.stripe_price_id)
    setSelectedSubscriptionStatus(data?.subscription_information?.status)
    setCurrentBusinessId(data?.id)
    setSubscriptionModal(true)
  }

  function handlePlanChange(priceId) {
    setSelectedPriceId(priceId)
  }
  function handleSubscriptionUpdate() {
    const data = {
      status: selectedSubscriptionStatus,
      stripe_price_id: selectedPriceId,
      is_manual_subscription: 1,
    }
    dispatch(updateBusinessSubscription(currentBusinessId, data))
  }

  function handleImpersonation(id) {
    dispatch(impersonationLogin(id, () => {
      Navigate('/')
    }))
  }
  return (
    <>
      {currentItems.map((index) => {
        return (
          <div className={`d-flex justify-content-between gap-4 flex-wrap bg-white rounded-lg border p-3 whitespace-nowrap mb-3 ${index.is_deleted ? 'border-danger' : ''}`} >
            <div className="inter d-flex align-items-center lg:w-60 ">
              <div className="px-3">
                <input
                  type="checkbox"
                  className="!accent-[#65558F] !border-[#C8C3C9] !border-[1.5px] scale-150	"
                />
              </div>

              <div>
                <p className="text-base font-semibold text-gray-700 mb-0">
                  {index?.email || "N/A"}
                </p>
                <p className="text-xs mb-0 text-gray-700 ">
                  {index?.business_name || "N/A"}
                </p>
                <p className="text-xs mb-0 text-gray-700">
                  {" "}
                  Joined: {moment(index?.created_at).format("DD MMM YYYY")}
                </p>
              </div>
            </div>
            <div className="d-flex align-items-center border-l border-r px-3 bg-white">
              <div className="text-center">
                <h1 className="font-medium text-gray-700 text-3xl inter mb-0 ">
                  {index?.orders_count}
                </h1>
                <p className="font-medium text-sm text-gray-700 mb-1 inter ">
                  Orders
                </p>
              </div>
            </div>
            <div className="d-flex justify-center items-start flex-col gap-2">
              <button
                type="button"
                onClick={() => {
                  if (index?.is_approved) {
                    return false;
                  } else {
                    handleSetAppoved(index?.id);
                  }
                }}
                className={`${index?.is_approved
                  ? "bg-green-500 text-white"
                  : "bg-green-200 text-green-700"
                  } p-2 font-semibold  text-sm min-w-24 rounded-full`}
              >
                {index?.is_approved ? (
                  "Approved"
                ) : (
                  <div className="flex items-center gap-1 justify-center">
                    {" "}
                    <FaCheck /> Approve
                  </div>
                )}
              </button>

              <button onClick={() => handleOpenSubscriptionModal(index)} className="p-2 font-semibold  text-sm min-w-24 rounded-full border-[1.5px] !border-customBlue bg-white text-customBlue">
                View and Update Subscription
              </button>
              <button onClick={() => handleImpersonation(index.id)} className="p-2 font-semibold  text-sm min-w-24 rounded-full border-[1.5px] !border-customBlue bg-white text-customBlue">
                Login to Business Account
              </button>
              <button
                type="button"
                onClick={() => {
                  if (index?.is_deleted) {
                    return false;
                  } else {
                    handleSetDelete(index?.id);
                  }
                }}
                className={`${index?.is_deleted
                  ? "bg-red-500 text-white"
                  : "bg-red-200 text-red-700"
                  } p-2 font-semibold  text-sm min-w-24 rounded-full`}
              >
                {index?.is_deleted ? (
                  "Deleted"
                ) : (
                  <div className="flex items-center gap-1 justify-center">
                    {" "}
                    <FaTrash /> Delete
                  </div>
                )}
              </button>
              <button
                type="button"
                onClick={() => {
                  if (index?.is_profile_locked) {
                    handleSetUnLock(index?.id);
                  } else {
                    handleSetLock(index?.id);
                  }
                }}
                className={`${index?.is_profile_locked
                  ? "bg-red-500 text-white"
                  : "bg-red-200 text-red-700"
                  } p-2 font-semibold  text-sm min-w-24 rounded-full`}
              >
                {index?.is_profile_locked ? (
                  <div className="flex items-center gap-1 justify-center">
                    {" "}
                    <FaUnlock /> Unlock
                  </div>
                ) : (
                  <div className="flex items-center gap-1 justify-center">
                    {" "}
                    <FaLock /> Lock
                  </div>
                )}
              </button>
            </div>

            <div className="d-flex align-items-center   border-l ps-3">
              <div>
                <div className=" form-switch black-switch d-flex text-start  ps-0">
                  <p className="mb-0 font-12 fw-medium  text-gray-700">
                    {index?.city}
                  </p>
                </div>
                <div className="d-flex  mt-2 text-start">
                  <p className="mb-0 font-12 fw-medium text-gray-700">
                    Campaigns:{" "}
                  </p>
                  <p className="mb-0 font-12 fw-medium text-gray-700">
                    {" "}
                    {index?.campaigns_count}
                  </p>
                </div>
                <div className="d-flex  mt-2 text-start">
                  <p className="mb-0 font-12 fw-medium text-gray-700">
                    Surveys:{" "}
                  </p>
                  <p className="mb-0 font-12 fw-medium text-gray-700">
                    {" "}
                    {index?.surveys_count}
                  </p>
                </div>
                <div className="d-flex  mt-2 text-start">
                  <p className="mb-0 font-12 fw-medium text-gray-700">
                    Loyalties:{" "}
                  </p>
                  <p className="mb-0 font-12 fw-medium text-gray-700">
                    {" "}
                    {index?.loyalties_count}
                  </p>
                </div>
                <div className="d-flex  mt-2 text-start">
                  <p className="mb-0 font-12 fw-medium text-gray-700">
                    Devices:{" "}
                  </p>
                  <p className="mb-0 font-12 fw-medium text-gray-700">
                    {" "}
                    {index?.devices_count}
                  </p>
                </div>
              </div>
            </div>

            <div className="d-flex align-items-center gap-2">
              <button onClick={() => Navigate(`/super/businessManagment/${index?.id}?email=${index.email}`)} className="h-7 flex items-center justify-center text-sm rounded-full p-3 bg-customBlue text-white">
                View
              </button>
              {/* <div className=" h-7 w-7 flex items-center justify-center rounded-lg bg-customBlue">
                <FiDownload size={18} color="white" />
              </div> */}
            </div>
            <div className="mt-3">
              <table className="table-auto w-full text-left text-sm">
                <tbody>
                  <tr>
                    <th className="p-2">Name:</th>
                    <td className="p-2">{index.firstname + ' ' + index.lastname || "N/A"}</td>
                  </tr>
                  <tr>
                    <th className="p-2">Email:</th>
                    <td className="p-2">{index.email || "N/A"}</td>
                  </tr>
                  <tr>
                    <th className="p-2">Business Name:</th>
                    <td className="p-2">{index.business_name || "N/A"}</td>
                  </tr>
                  <tr>
                    <th className="p-2">Phone:</th>
                    <td className="p-2">{index.phone || "N/A"}</td>
                  </tr>
                  <tr>
                    <th className="p-2">Address:</th>
                    <td className="p-2">{index.business_address || "N/A"}</td>
                  </tr>
                  <tr>
                    <th className="p-2">Business Category:</th>
                    <td className="p-2">{index.business_category || "N/A"}</td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        );
      })}
      <Modal centered className="rounded-3xl" show={subscriptionModal} onHide={() => setSubscriptionModal(false)}>
        <Modal.Body>
          <div className=" w-full inter p-4">
            <h5 className="text-lg font-semibold mb-3 text-start">
              Subscription
            </h5>
            <div>
              <p>
                Subscription Status
              </p>
              <FormControl className="mui-select-field   w-100">
                <Select required
                  value={selectedSubscriptionStatus}
                  className="rounded-5 w-100 text-start font-17"
                  onChange={(e) =>
                    setSelectedSubscriptionStatus(e.target.value)
                  }
                  displayEmpty
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                >
                  <MenuItem value={'active'}>Active</MenuItem>
                  <MenuItem value={'holdandrequestpayment'}>Hold and request Payment</MenuItem>
                  <MenuItem value={'canceled'}>Canceled</MenuItem>
                </Select>
              </FormControl>
            </div>
            <p className="my-3">
              Selected Plan
            </p>
            <div className="mt-2 cursor-pointer" onClick={() => { handlePlanChange(essentialPlan[0]) }}>
              <div className={` rounded-xl p-2  inter ${essentialPlan.includes(selectedPriceId) ? "border-2 !border-blue-500" : "border"}`}>
                <div className="flex flex-wrap gap-2 font-medium text-base">
                  <div className=" ">Starter Plan</div>
                </div>
                <p className="text-[#718EBF] text-sm mt-2 mb-0">
                  Price Id from Stripe 1234565432
                </p>
              </div>
            </div>
            <div className="mt-2 cursor-pointer" onClick={() => { handlePlanChange(growthPlan[0]) }}>
              <div className={` rounded-xl p-2  inter ${growthPlan.includes(selectedPriceId) ? "border-2 !border-blue-500" : "border"}`}>
                <div className="flex flex-wrap gap-2 font-medium text-base">
                  <div className=" ">Essential Plan</div>
                </div>
                <p className="text-[#718EBF] text-sm mt-2 mb-0">
                  Price Id from Stripe 1234565432
                </p>
              </div>
            </div>
            <div className="mt-2 cursor-pointer" onClick={() => { handlePlanChange(elitePlan[0]) }}>
              <div className={` rounded-xl p-2  inter ${elitePlan.includes(selectedPriceId) ? "border-2 !border-blue-500" : "border"}`}>
                <div className="flex flex-wrap gap-2 font-medium text-base">
                  <div className=" ">Growth Plan</div>
                </div>
                <p className="text-[#718EBF] text-sm mt-2 mb-0">
                  Price Id from Stripe 1234565432
                </p>
              </div>
            </div>

            <div className="container-fluid">

              <div className="row mt-4 justify-content-between ">
                <div className="col-lg-4 col-md-4 col-6 text-center">
                  <Button
                    className="btn-white-borderTwo rounded-5 w-100  p-2.5 fw-bold font-20 text-capitalize"
                    onClick={() => setSubscriptionModal(false)} >
                    Cancel
                  </Button>
                </div>
                <div className="col-lg-4 col-md-4 col-6 text-center">
                  <Button type='button'
                    onClick={handleSubscriptionUpdate}
                    className="btn-blueTwo rounded-5 w-100 p-2.5 fw-bold font-20 text-capitalize"
                  >
                    {isLoading ? <Spinner /> : 'Save'}
                  </Button>
                </div>
              </div>
            </div>

          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
