/* eslint-disable eqeqeq */
import React, { useEffect, useRef, useState } from "react";
import upload from "../assets/imges/upload.png";
import SideBar from "../components/SideBar";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from "@mui/material";
import { Modal } from "react-bootstrap";
import AutocompleteGoggle from "react-google-autocomplete";
import { FaAngleDown, FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import {
  FaArrowRightLong,
  FaArrowRotateLeft,
  FaArrowRotateRight,
  FaCrown,
} from "react-icons/fa6";
import { IoMdCheckmark, IoMdClose } from "react-icons/io";
import PhoneInput from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import avtar from "../assets/imges/admin.png";
import toggle from "../assets/imges/table-toggle.png";

import AvatarEditor from "react-avatar-editor";
import { FiUpload, FiZoomIn, FiZoomOut } from "react-icons/fi";
import { LuLogOut } from "react-icons/lu";
import { Input, Spinner } from "reactstrap";
import {
  createBusinessAutomation,
  createCheckout,
  getBusinessAutomations,
  getPriceDetails,
  getSubscriptionDetails,
  seTgetErrorNull,
  updateBusinessProfile,
} from "../redux/slices/userSlice";
import { IoCheckmark } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import { loadStripe } from "@stripe/stripe-js";
import FullScreenLoader from "../components/loaders/FullScreenLoader";
import moment from "moment";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
const Setting = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();

  const {
    isLoading,
    getError,
    businessAutomations,
    userDetail,
    priceDetails,
    allPrices,
    subscriptionDetails,
  } = useSelector((state) => state.user);

  const [price, setPrice] = useState(null);
  const [businessName, setBusinessName] = useState("");
  const [businessEmail, setBusinessEmail] = useState("");
  const [businessWebsite, setBusinessWebsite] = useState("");
  const [businessLogo, setBusinessLogo] = useState("");
  const [newBusinessLogo, setNewBusinessLogo] = useState(null);
  const [businessCategory, setBusinessCategory] = useState(null);
  const [businessAddress, setBusinessAddress] = useState("");
  const [businessnumber, setBusinessNumber] = useState();
  const [primaryFirstName, setPrimaryFirstName] = useState("");
  const [primarySurname, setPrimarySurname] = useState("");
  const [primaryEmail, setPrimaryEmail] = useState("");
  const [primaryNumber, setPrimaryNumber] = useState();
  const [businessKey, setBusinessKey] = useState("");
  const [loginBusinessKey, setLoginBusinessKey] = useState("");
  const [loginOtp, setLoginOtp] = useState("");
  const [billingTab, setBillingTab] = useState("essential");
  const [companyName, setCompanyName] = useState("");
  const [companyABN, setCompanyABN] = useState("");
  const [companyACN, setCompanyACN] = useState("");
  const [companyFirstName, setCompanyFirstName] = useState("");
  const [companySurname, setCompanySurname] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyPostCode, setCompanyPostCode] = useState("");
  const [companyCity, setCompanyCity] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [companyCountry, setCompanyCountry] = useState("");
  const [privateName, setPrivateName] = useState("");
  const [privateABN, setPrivateABN] = useState("");
  const [privateACN, setPrivateACN] = useState("");
  const [privateFirstName, setPrivateFirstName] = useState("");
  const [privateSurname, setPrivateSurname] = useState("");
  const [privateAddress, setPrivateAddress] = useState("");
  const [privatePostCode, setPrivatePostCode] = useState("");
  const [privateCity, setPrivateCity] = useState("");
  const [privateEmail, setPrivateEmail] = useState("");
  const [privateCountry, setPrivateCountry] = useState("");
  const [automationTitle, setAutomationTitle] = useState();
  const [automationDetail, setAutomationDetail] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showAutomationDataModal, setShowAutomationDataModal] = useState(false);
  const [showAutomationData, setShowAutomationData] = useState();
  const [type, setType] = useState("password");
  const [trigger, setTrigger] = useState();
  const [action, setAction] = useState();
  const [content, setContent] = useState();
  const [paymentFlag, setPaymentFlag] = useState(false);
  //   ---------- image crop ----------
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [editImgModal, setEditImgModal] = useState(false);
  const [image, setImage] = useState("");
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [cnvsImage, setCnvsImage] = useState("");

  // ---- logo crop function
  const [fileLogo, setFileLogo] = useState(null);
  // const [fileNameLogo, setFileNameLogo] = useState(null);
  const [editImgModalLogo, setEditImgModalLogo] = useState(false);
  const [imageLogo, setImageLogo] = useState("");
  const [scaleLogo, setScaleLogo] = useState(1);
  const [rotateLogo, setRotateLogo] = useState(0);
  const [cnvsImageLogo, setCnvsImageLogo] = useState("");

  const [myPlan, setMyPlan] = useState("");
  const [selectedPrice, setSelectedPrice] = useState(
    "price_1PXbxtEhsh9AaJCv8OsIRqm4"
  );
  const [iframeUrl, setIframeUrl] = useState("");
  const [isIframeVisible, setIsIframeVisible] = useState(false);
  const [profileImg, setProfileImg] = useState(null);

  const updateIframeUrl = (url) => {
    setIframeUrl(url);
    setIsIframeVisible(true);
  };

  const closeModal = () => {
    setIsIframeVisible(false);
    setIframeUrl(""); // Optionally clear the URL when closing the modal
  };
  const tabRef = useRef();

  useEffect(() => {
    if (selectedPrice && allPrices) {
      setPrice(allPrices.find((_price) => _price.id == selectedPrice));
    } else {
      // dispatch(getPriceDetails(selectedPrice));
    }
  }, [selectedPrice]);
  const previewRef = useRef();
  const previewRefLogo = useRef();

  // useEffect(()=>{
  //     if(setShowAutomationData){
  //         setShowAutomationDataModal(true)
  //     }
  // },[showAutomationData])

  const handlePreview = () => {
    if (previewRef.current) {
      const cnvs = previewRef.current.getImage();
      cnvs.toBlob((blob) => {
        setFile(blob);

        const previousImg = URL.createObjectURL(blob);
        setCnvsImage(previousImg);
      });
      setEditImgModal(false);
      setFileName(image?.name);
      setImage("");
      console.log(cnvsImage, "-----final");
    }
  };
  const handlePreviewLogo = () => {
    if (previewRefLogo.current) {
      const cnvs = previewRefLogo.current.getImage();
      // console.log("canvas get image ", cnvs);
      cnvs.toBlob((blob) => {
        setFileLogo(blob);

        const previousImg = URL.createObjectURL(blob);
        setCnvsImageLogo(previousImg);
      });
      setEditImgModalLogo(false);
      // setFileNameLogo(image?.name);
      setImageLogo("");
    }
  };
  useEffect(() => {
    if (Object.keys(userDetail).length > 0) {
      setBusinessKey(userDetail.business_key);
      setBusinessAddress(userDetail.business_address);
      setBusinessName(userDetail.business_name);
      setBusinessEmail(userDetail.email);
      setBusinessNumber(userDetail.phone);
      setBusinessWebsite(userDetail.website_url);
      setBusinessLogo(userDetail.image);
      setBusinessCategory(userDetail.business_category);
      setPrimaryFirstName(userDetail.primary_contact_f_name);
      setPrimarySurname(userDetail.primary_contact_l_name);
      setPrimaryEmail(userDetail.primary_contact_email);
      setPrimaryNumber(userDetail.primary_contact_phone);
      setFileName(userDetail?.cover_image);
      if (userDetail.private_tax_information) {
      }
    }
  }, [userDetail]);
  // add ids of prices in array to make the current package acitve
  const plans = [];
  useEffect(() => {
    if (getError && getError.length > 1) {
      toast.error(getError, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      dispatch(seTgetErrorNull());
    }
  }, [getError]);
  useEffect(() => {
    if (query.get("activeTab") == "billing") {
      tabRef.current.click();
    }
    if (userDetail?.subscription_information?.stripe_price_id) {
      // dispatch(getSubscriptionDetails());
      if (
        [
          'price_1QV39FEhsh9AaJCvODSIlKlZ', 'price_1QV39FEhsh9AaJCvH07TeW0b'
        ].includes(userDetail?.subscription_information?.stripe_price_id)
      ) {
        setBillingTab("starter");
        setMyPlan("starter");
      }
      if (
        [
          'price_1QV39IEhsh9AaJCv6v2V5Ns0', 'price_1QV39IEhsh9AaJCvG5qSRm9n'
        ].includes(userDetail?.subscription_information?.stripe_price_id)
      ) {
        setBillingTab("essential");
        setMyPlan("essential");
      }
      if (
        [
          'price_1QV39KEhsh9AaJCvapLd8zqi', 'price_1QV39KEhsh9AaJCvheykDdJG'
        ].includes(userDetail?.subscription_information?.stripe_price_id)
      ) {
        setBillingTab("growth");
        setMyPlan("growth");
      }
      setSelectedPrice(userDetail?.subscription_information?.stripe_price_id);
    }
    dispatch(getBusinessAutomations());
  }, []);
  useEffect(() => {
    if (userDetail) {
      if (userDetail?.subscription_information?.stripe_price_id) {
        if (
          [
            'price_1QV39FEhsh9AaJCvODSIlKlZ', 'price_1QV39FEhsh9AaJCvH07TeW0b'
          ].includes(userDetail?.subscription_information?.stripe_price_id)
        ) {
          setBillingTab("starter");
        }
        if (
          [
            'price_1QV39IEhsh9AaJCv6v2V5Ns0', 'price_1QV39IEhsh9AaJCvG5qSRm9n'
          ].includes(userDetail?.subscription_information?.stripe_price_id)
        ) {
          setBillingTab("essential");
        }
        if (
          [
            'price_1QV39KEhsh9AaJCvapLd8zqi', 'price_1QV39KEhsh9AaJCvheykDdJG'
          ].includes(userDetail?.subscription_information?.stripe_price_id)
        ) {
          setBillingTab("growth");
        }
        setSelectedPrice(userDetail?.subscription_information?.stripe_price_id);
      }
    }
  }, [userDetail]);

  function handleCreateCheckout() {
    var data = {
      line_items: [{ price: selectedPrice, quantity: 1 }],
      mode: "subscription",
    };
    dispatch(createCheckout(data, handleCheckoutResponse));
  }
  function handleCheckoutResponse(data) {
    window.location.replace(data.url);
    // updateIframeUrl(data.url)
  }
  const toggleType = () => {
    if (type == "password") {
      setType("text");
    } else {
      setType("password");
    }
  };
  function handleCreateAutomation(e) {
    e.preventDefault();
    if (automationTitle && trigger && action && content) {
      if (content == "Custom message" && !automationDetail) {
        toast.error("Please fill all the fields", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return false;
      }
      var data = {
        title: automationTitle,
        trigger: trigger,
        action: action,
        content: content == "Custom message" ? automationDetail : content,
      };
      dispatch(createBusinessAutomation(data, handleResponse));
    } else {
      toast.error("Please fill all the fields", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }
  function handleResponse(data) {
    setAutomationDetail(null);
    setAutomationTitle(null);
    setContent(null);
    setTrigger(null);
    setAction(null);
    setShowModal(false);
    dispatch(getBusinessAutomations());
  }
  const businessOption = [
    { label: "Fast Food / take Away Restaurants" },
    { label: "Cafe" },
    { label: "Burger Shop" },
    { label: "Pizzeria" },
    { label: "Food Trucks and Mobile Vendor" },
    { label: "Juice Bar and Smoothie Shops" },
    { label: "Bars and Pubs" },
    { label: "Pharmacy" },
    { label: "Bakeries or Dessert Shop" },
    { label: "Other" },
  ];

  const handleSubmitBusinessDetails = (e) => {
    console.log(newBusinessLogo, " ---new business logo");
    e.preventDefault();
    var data = new FormData();
    // Add other data to the form data object
    data.append("business_name", businessName);
    data.append("business_category", businessCategory);
    data.append("website_url", businessWebsite);
    data.append("phone", businessnumber);
    data.append("business_address", businessAddress);
    if (fileLogo) {
      console.log("🚀 ~ handleSubmitBusinessDetails ~ fileLogo:", fileLogo);
      data.append("images", fileLogo);
    }
    if (file) {
      console.log("🚀 ~ handleSubmitBusinessDetails ~ file:", file);
      data.append("cover_images", file);
    }

    dispatch(updateBusinessProfile(data));
  };

  const handleSubmitPrimaryDetails = (e) => {
    e.preventDefault();
    var data = new FormData();
    // Add other data to the form data object
    data.append("primary_contact_f_name", primaryFirstName);
    data.append("primary_contact_l_name", primarySurname);
    data.append("primary_contact_email", primaryEmail);
    data.append("primary_contact_phone", primaryNumber);
    dispatch(updateBusinessProfile(data));
  };
  const handleLogout = () => {
    localStorage.clear();
  };
  useEffect(() => {
    if (!localStorage.getItem("userToken")) {
      navigate("/auth/login", { replace: true });
    }
  }, []);
  return (
    <section className="business_admin_main">
      <FullScreenLoader isLoading={isLoading} />
      <div className="container-fluid setting-page">
        <div className="row order ">
          {/* <!-- ===========SID-BAR======= --> */}
          <SideBar />
          {/* <!-- ========= content body ======= --> */}

          <div className="w-lg-80  inter h-screen overflow-y-scroll px-0">
            {/* <!-- ===========HEADER========== --> */}
            <Header PageTitle="Setting" />
            <div className="px-lg-4 px-md-3 px-2">
              <div className="Setting mt-3 mb-3 ">
                {/* <h5 className='font-20'>Setting</h5> */}
                <div className="flex justify-center align-items-start w-100">
                  {/* <h4 className='fw-bold inter mb-0'>Setting</h4> */}

                  <ul
                    className="nav nav-pills w-full bg-white setting-main-tab mb-3 p-3 rounded-4 "
                    id="pills-tab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <button
                        onClick={() => setPaymentFlag(false)}
                        className="nav-link active"
                        id="pills-general-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-general"
                        type="button"
                        role="tab"
                        aria-controls="pills-general"
                        aria-selected="true"
                      >
                        General Information
                      </button>
                    </li>

                    <li className="nav-item" role="presentation">
                      <button
                        onClick={() => setPaymentFlag(false)}
                        className="nav-link"
                        id="pills-billing-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-billing"
                        type="button"
                        role="tab"
                        aria-controls="pills-billing"
                        aria-selected="false"
                        ref={tabRef}
                      >
                        Billing
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        onClick={() => setPaymentFlag(false)}
                        className="nav-link"
                        id="pills-sms-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-sms"
                        type="button"
                        role="tab"
                        aria-controls="pills-sms"
                        aria-selected="false"
                      >
                        SMS Automations
                      </button>
                    </li>
                  </ul>
                </div>

                <div className="tab-content" id="pills-tabContent">
                  {/* ----General Information--- */}
                  <div
                    className="tab-pane fade show active"
                    id="pills-general"
                    role="tabpanel"
                    aria-labelledby="pills-general-tab"
                    tabIndex={0}
                  >
                    <div className="container px-0">
                      <div className="container bg-color p-3 rounded-4">
                        <form
                          className="row justify-content-center"
                          onSubmit={handleSubmitBusinessDetails}
                        >
                          <h5 className="font-semibold mb-3">
                            Business Information
                          </h5>
                          <div className="col-lg-2 col-12">
                            <div className="relative inline-block">
                              <img
                                src={
                                  cnvsImageLogo
                                    ? cnvsImageLogo // Use profileImg if available
                                    : userDetail?.image || // Fallback to userDetail.image if profileImg is not available
                                    "https://via.placeholder.com/100x100.png" // Final fallback to placeholder image
                                }
                                alt={"Profile"}
                                className="w-32 h-32 !rounded-2xl shadow object-cover"
                              />

                              <div
                                onClick={() => setEditImgModalLogo(true)}
                                className="absolute bottom-0 right-0 cursor-pointer"
                              >
                                <svg
                                  width="40"
                                  height="40"
                                  viewBox="0 0 40 40"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <circle
                                    cx="20"
                                    cy="20"
                                    r="19"
                                    fill="#F196FD"
                                    stroke="white"
                                    stroke-width="2"
                                  />
                                  <g clip-path="url(#clip0_6084_4534)">
                                    <path
                                      d="M29.105 15.3959L27.4843 17.0166C27.3191 17.1818 27.0519 17.1818 26.8867 17.0166L22.9843 13.1143C22.8191 12.949 22.8191 12.6818 22.9843 12.5166L24.605 10.8959C25.2624 10.2385 26.3312 10.2385 26.9921 10.8959L29.105 13.0088C29.766 13.6662 29.766 14.735 29.105 15.3959ZM21.5921 13.9088L12.3601 23.1408L11.6148 27.4123C11.5128 27.9889 12.0156 28.4881 12.5921 28.3897L16.8636 27.6408L26.0957 18.4088C26.2609 18.2436 26.2609 17.9764 26.0957 17.8111L22.1933 13.9088C22.0246 13.7436 21.7574 13.7436 21.5921 13.9088ZM15.9636 22.3498C15.7703 22.1564 15.7703 21.8471 15.9636 21.6537L21.3777 16.2396C21.571 16.0463 21.8804 16.0463 22.0738 16.2396C22.2671 16.433 22.2671 16.7424 22.0738 16.9357L16.6597 22.3498C16.4664 22.5432 16.157 22.5432 15.9636 22.3498ZM14.6945 25.3064H16.382V26.5826L14.1144 26.9799L13.021 25.8865L13.4183 23.6189H14.6945V25.3064Z"
                                      fill="white"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_6084_4534">
                                      <rect
                                        width="18"
                                        height="18"
                                        fill="white"
                                        transform="translate(11.6016 10.3999)"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </div>
                              {/* <input
                                type="file"
                                className="hidden"
                                onChange={(e) =>
                                  setProfileImg(e.target.files[0])
                                }
                                id="profile-img"
                                accept=".jpg, .jpeg, .png"
                              /> */}
                            </div>
                          </div>
                          <div className="col-lg-10 col-12 mt-lg-0 mt-3">
                            <div className="row bussiness-placeholder">
                              <div className="col-lg-6 col-md-6 col-12 ">
                                <p className=" text-dark mb-1 text-start font-13 fw-medium ">
                                  Business Name*
                                </p>
                                <input
                                  disabled
                                  type="text"
                                  value={businessName}
                                  onChange={(e) =>
                                    setBusinessName(e.target.value)
                                  }
                                  className="border w-full h-12 !placeholder:text-[#718EBF]focus:outline-none text-sm p-2.5 rounded-xl text-black"
                                  required
                                  placeholder="E.g. Time Link"
                                />
                              </div>
                              <div className="col-lg-6 col-md-6 col-12 flex items-end  mt-lg-0 mt-3 ">
                                {fileName || cnvsImage ? (
                                  <div className="border w-full h-28 rounded-xl relative">
                                    <img
                                      src={cnvsImage || fileName}
                                      alt=""
                                      className="w-full h-full object-cover rounded-xl"
                                    />
                                    <div
                                      onClick={() => setEditImgModal(true)}
                                      className="absolute -bottom-2 -right-2 cursor-pointer"
                                    >
                                      <svg
                                        width="40"
                                        height="40"
                                        viewBox="0 0 40 40"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <circle
                                          cx="20"
                                          cy="20"
                                          r="19"
                                          fill="#F196FD"
                                          stroke="white"
                                          stroke-width="2"
                                        />
                                        <g clip-path="url(#clip0_6084_4534)">
                                          <path
                                            d="M29.105 15.3959L27.4843 17.0166C27.3191 17.1818 27.0519 17.1818 26.8867 17.0166L22.9843 13.1143C22.8191 12.949 22.8191 12.6818 22.9843 12.5166L24.605 10.8959C25.2624 10.2385 26.3312 10.2385 26.9921 10.8959L29.105 13.0088C29.766 13.6662 29.766 14.735 29.105 15.3959ZM21.5921 13.9088L12.3601 23.1408L11.6148 27.4123C11.5128 27.9889 12.0156 28.4881 12.5921 28.3897L16.8636 27.6408L26.0957 18.4088C26.2609 18.2436 26.2609 17.9764 26.0957 17.8111L22.1933 13.9088C22.0246 13.7436 21.7574 13.7436 21.5921 13.9088ZM15.9636 22.3498C15.7703 22.1564 15.7703 21.8471 15.9636 21.6537L21.3777 16.2396C21.571 16.0463 21.8804 16.0463 22.0738 16.2396C22.2671 16.433 22.2671 16.7424 22.0738 16.9357L16.6597 22.3498C16.4664 22.5432 16.157 22.5432 15.9636 22.3498ZM14.6945 25.3064H16.382V26.5826L14.1144 26.9799L13.021 25.8865L13.4183 23.6189H14.6945V25.3064Z"
                                            fill="white"
                                          />
                                        </g>
                                        <defs>
                                          <clipPath id="clip0_6084_4534">
                                            <rect
                                              width="18"
                                              height="18"
                                              fill="white"
                                              transform="translate(11.6016 10.3999)"
                                            />
                                          </clipPath>
                                        </defs>
                                      </svg>
                                    </div>
                                  </div>
                                ) : (
                                  <div className=" flex items-center h-28 w-full gap-2 ">
                                    {/* <div className=""> */}
                                    {/* <p className=" text-dark mb-1 text-start font-13 fw-medium ">
                                        Logo
                                      </p>
                                      <img
                                        height={180}
                                        width={180}
                                        className="rounded-4 mt-2"
                                        src={
                                          cnvsImage ? cnvsImage : businessLogo
                                        }
                                        alt="loggo"
                                      />
                                    </div> */}

                                    <div
                                      role="button"
                                      onClick={() => setEditImgModal(true)}
                                      className="w-full h-full border rounded-xl flex justify-center items-center p-[12px] text-center"
                                    >
                                      <div>
                                        <p className="mb-0 text-sm justify-center flex items-center gap-2 text-black inter point">
                                          <FiUpload size={20} /> Upload Image
                                        </p>
                                        <p className=" text-[#718EBF] mb-0 text-xs  ">
                                          * file types are JPG, PNG, PDF.
                                        </p>
                                      </div>
                                    </div>

                                    {/* <input type="file" onChange={(e) => { setNewBusinessLogo(e.target.files[0]); setBusinessLogo(URL.createObjectURL(e.target.files[0])) }} id="file" className="d-none" /> */}
                                  </div>
                                )}
                              </div>
                              <div className="col-lg-6 col-md-6 col-12  mt-3 serial-number">
                                <p className=" text-dark mb-1 text-start font-13 fw-medium ">
                                  Category
                                </p>
                                <input
                                  disabled
                                  value={businessCategory}
                                  className="border w-full h-12 !placeholder:text-red-800 focus:outline-none text-sm p-2.5 rounded-xl text-black"
                                  placeholder="E.g. Coffee Shop"
                                  style={{ padding: "12px" }}
                                />
                              </div>
                              <div className="col-lg-6 col-md-6 col-12  mt-3">
                                <p className=" text-dark mb-1 text-start font-13 fw-medium ">
                                  Email*
                                </p>
                                <input
                                  type="email"
                                  required
                                  value={businessEmail}
                                  onChange={(e) =>
                                    setBusinessEmail(e.target.value)
                                  }
                                  className="border w-full h-12 !placeholder:text-red-800 focus:outline-none text-sm p-2.5 rounded-xl text-black"
                                  placeholder="E.g. John@gmail.com"
                                  style={{ padding: "12px" }}
                                />
                              </div>
                              <div className="col-lg-6 col-md-6 col-12   mt-3">
                                <p className=" text-dark mb-1 text-start font-13 fw-medium ">
                                  Phone number*
                                </p>
                                {/* <div className="setting-page-phone-number "> */}
                                <div className="forget-number-field    forget-number-fieldGrater setting-page-phone-number  forget-number-fieldLower text-black">
                                  <PhoneInput
                                    className="!text-black"
                                    cont
                                    required
                                    international
                                    defaultCountry="AU"
                                    value={businessnumber}
                                    placeholder="95765 744 7656"
                                    onChange={setBusinessNumber}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6 col-12   mt-3 serial-number">
                                <p className=" text-dark mb-1 text-start font-13 fw-medium ">
                                  Business Address*
                                </p>
                                <AutocompleteGoggle
                                  apiKey="AIzaSyBNCaWnm1qc_mePajPWwhS_C5xr-QmjlpI"
                                  style={{ width: "100%", padding: "12px" }}
                                  className="border w-full h-12 !placeholder:text-red-800 focus:outline-none text-sm p-2.5 rounded-xl"
                                  onPlaceSelected={(place) => {
                                    console.log(
                                      "formated addres" +
                                      place.formatted_address
                                    );
                                    setBusinessAddress(place.formatted_address);
                                  }}
                                  options={
                                    {
                                      types: ["address"], // Prioritize exact addresses
                                      // componentRestrictions: { country: "us" },
                                    }
                                  }
                                  placeholder="Business Address *"
                                  defaultValue={businessAddress}
                                // defaultValue="Amsterdam"
                                />
                              </div>
                              <div className="col-lg-6 col-md-6 col-12 mt-3">
                                <p className=" text-dark mb-1 text-start font-13 fw-medium ">
                                  Website URL
                                </p>
                                <input
                                  type="text"
                                  value={businessWebsite}
                                  onChange={(e) =>
                                    setBusinessWebsite(e.target.value)
                                  }
                                  className="border w-full h-12 focus:outline-none text-sm p-2.5 rounded-xl !placeholder:text-[#718EBF]"
                                  placeholder="https://www.timelink.au"
                                  style={{ padding: "12px" }}
                                />
                              </div>
                              <div className="col-lg-6 col-md-6 col-12 mt-3">
                                <p className=" text-dark mb-1 text-start font-13 fw-medium ">
                                  Business Key
                                </p>
                                <div className="input-group border h-12 w-full !placeholder:text-red-800 focus:outline-none text-sm p-2.5 rounded-xl">
                                  <input
                                    type={type}
                                    readOnly
                                    className="form-control border-0 rounded-5 !text-sm py-0 px-1 !text-black"
                                    value={businessKey}
                                    onChange={(e) =>
                                      setBusinessKey(e.target.value)
                                    }
                                  />
                                  <span
                                    className="input-group-text border-0 bg-transparent rounded-5 cursor-pointer"
                                    onClick={toggleType}
                                    id="basic-addon1"
                                  >
                                    {type != "password" ? (
                                      <FaRegEye
                                        size={20}
                                        color="rgba(100, 116, 139, 1)"
                                      />
                                    ) : (
                                      <FaRegEyeSlash
                                        size={20}
                                        color="rgba(100, 116, 139, 1)"
                                      />
                                    )}
                                  </span>
                                </div>
                              </div>

                              <div className=" mt-3  text-end">
                                <button
                                  type="submit"
                                  className="btn-blue rounded-5  px-5 py-2 fw-medium font-14 text-capitalize"
                                >
                                  {isLoading ? <Spinner size={"sm"} /> : "Save"}
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      {/* =========== PRIMARY ========== */}
                      {/* <div className="container bg-color p-3 rounded-4 mt-3">
                        <div className="row justify-content-center">
                          <div className="col-lg-3 col-12">
                            <div>
                              <h5 className="font-20 fw-semibold inter colorblack">
                                Primary Contact
                              </h5>
                            </div>
                          </div>
                          <form
                            className="col-lg-9 col-12 mt-lg-0 mt-3"
                            onSubmit={handleSubmitPrimaryDetails}
                          >
                            <div className="row">
                              <div className="col-lg-6 col-md-6 col-12 ">
                                <p className=" text-dark mb-1 text-start font-13 fw-medium ">
                                  First Name*
                                </p>
                                <input
                                  type="text"
                                  required
                                  value={primaryFirstName}
                                  onChange={(e) =>
                                    setPrimaryFirstName(e.target.value)
                                  }
                                  className="form-control  input-field  font-13 px-3 rounded-5"
                                  placeholder="E.g. John"
                                  style={{ padding: "12px" }}
                                />
                              </div>
                              <div className="col-lg-6 col-md-6 col-12 mt-lg-0 mt-md-0 mt-2 ">
                                <p className=" text-dark mb-1 text-start font-13 fw-medium ">
                                  Last name*
                                </p>
                                <input
                                  type="text"
                                  required
                                  value={primarySurname}
                                  onChange={(e) =>
                                    setPrimarySurname(e.target.value)
                                  }
                                  className="form-control  input-field  font-13 px-3 rounded-5"
                                  placeholder="E.g. Doe"
                                  style={{ padding: "12px" }}
                                />
                              </div>
                              <div className="col-lg-6 col-md-6 col-12 mt-2 ">
                                <p className=" text-dark mb-1 text-start font-13 fw-medium ">
                                  Email*
                                </p>
                                <input
                                  type="email"
                                  required
                                  value={primaryEmail}
                                  onChange={(e) =>
                                    setPrimaryEmail(e.target.value)
                                  }
                                  className="form-control  input-field  font-13 px-3 rounded-5"
                                  placeholder="E.g. John@gmail.com"
                                  style={{ padding: "12px" }}
                                />
                              </div>
                              <div className="col-lg-6 col-md-6 col-12 mt-2 ">
                                <p className=" text-dark mb-1 text-start font-13 fw-medium ">
                                  Phone number*
                                </p>
                                <div className="forget-number-field    forget-number-fieldGrater setting-page-phone-number  forget-number-fieldLower">
                                  <PhoneInput
                                    className=""
                                    required
                                    international
                                    defaultCountry="AU"
                                    value={primaryNumber}
                                    placeholder="95765 744 7656"
                                    onChange={setPrimaryNumber}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className=" mt-3 text-end">
                              <button
                                type="submit"
                                className="btn-blue rounded-5  px-5 py-2 fw-medium font-14 text-capitalize"
                              >
                                {isLoading ? <Spinner size={10} /> : "Save"}
                              </button>
                            </div>
                          </form>
                        </div>
                      </div> */}
                      {/* ======== LOGIN =========== */}
                      {/* <div className="container bg-color p-3 rounded-4 mt-3">
                        <form className="row">
                          <div className="col-lg-3 col-12 ">
                            <div>
                              <h5 className="font-20 fw-semibold inter colorblack">
                                Log In{" "}
                              </h5>
                            </div>
                          </div>
                          <div className="col-lg-9 col-12 mt-lg-0 mt-3">
                            <div className="row">
                              <div className="col-12 mt-">
                                <p className=" text-dark mb-1 text-start font-13 fw-medium ">
                                  Business Key
                                </p>
                                <form
                                  className="input-group rounded-5"
                                  style={{
                                    border: "1.5px solid rgb(200, 195, 201)",
                                    padding: "9px 9px 9px 12px",
                                  }}
                                >
                                  <input
                                    type="email"
                                    required
                                    value={loginBusinessKey}
                                    onChange={(e) =>
                                      setLoginBusinessKey(e.target.value)
                                    }
                                    className="form-control border-0 font-13 rounded-5 py-0 px-1"
                                    placeholder="john.doe@gmail.com"
                                    style={{ color: "rgb(200, 195, 201)" }}
                                  />
                                  <span className="input-group-text border-0 bg-transparent pe-0 py-0 ps-1 rounded-5">
                                    <button
                                      type="submit"
                                      className="btn-blue rounded-5  px-3 py-1 fw-medium font-14 text-capitalize"
                                    >
                                      Change
                                    </button>
                                  </span>
                                </form>
                              </div>
                              <form className="col-lg-10 col-12  mt-2">
                                <p className=" text-dark text-uppercase  mb-1 text-start font-13 fw-medium ">
                                  OTP
                                </p>
                                <div className="d-flex  align-items-center ">
                                  <input
                                    type="text"
                                    required
                                    value={loginOtp}
                                    onChange={(e) =>
                                      setLoginOtp(e.target.value)
                                    }
                                    className="form-control  input-field  font-13 px-3 rounded-5"
                                    placeholder="E.g. 54886"
                                    style={{ padding: "12px" }}
                                  />
                                  <button
                                    type="submit"
                                    className="btn-blue rounded-5 ms-3  px-4 py-2 fw-medium font-14 text-capitalize"
                                  >
                                    Confirm
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </form>
                      </div> */}
                    </div>
                  </div>

                  {/* =========== billing  */}
                  <div
                    className="tab-pane fade"
                    id="pills-billing"
                    role="tabpanel"
                    aria-labelledby="pills-billing-tab"
                    tabIndex={0}
                  >
                    <div className="container-lg container-fluid bg-color mb-4 p-3 rounded-4">
                      <div className="">
                        {isLoading ? (
                          <div className="w-full h-full">
                            <Spinner size={"md"} color="black" />
                          </div>
                        ) : (
                          <div className=" w-full">
                            <h2 className="text-xl font-semibold mb-3 text-start">
                              My Subscription
                            </h2>
                            <div className="mb-6">
                              <p className=" font-medium m-2">Current Plan</p>
                              <div className="border rounded-xl p-4  inter">
                                <div className="flex gap-2 font-medium text-base">
                                  <div className=" ">
                                    {
                                      subscriptionDetails?.items[0]?.product
                                        ?.name
                                    }
                                  </div>
                                  {myPlan === "essential" && (
                                    <div className="text-[#FC9A08] ml-2 flex items-center">
                                      <FaCrown className="mr-1" />
                                      Popular Plan
                                    </div>
                                  )}
                                </div>
                                <p className="text-[#718EBF] text-sm mt-2 mb-0">
                                  Next billing date:{" "}
                                  {moment(
                                    subscriptionDetails?.next_billing_date
                                  ).format("DD/MM/YYYY")}
                                </p>
                              </div>
                            </div>
                            <p className="text-black font-medium text-center text-sm">
                              To modify or cancel your subscription, please
                              contact our support team on{" "}
                              <a
                                href="mailto:support@timelink.au"
                                className=" text-black underline font-medium "
                              >
                                support@timelink.au
                              </a>
                              .
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* ========== SMS AUTOMATION =========== */}
                  <div
                    className="tab-pane fade"
                    id="pills-sms"
                    role="tabpanel"
                    aria-labelledby="pills-sms-tab"
                    tabIndex={0}
                  >
                    <div className="container-fluid px-0">
                      <div className="row mb-3">
                        <div className="col-4">
                          <div className="d-flex justify-content-between align-items-center">
                            <div>
                              <h6 className="font-18 inter font-600 colorblack">
                                SMS Automations
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div className="col-1" />
                        <div className="col-7 text-end">
                          <div>
                            <button
                              onClick={() => setShowModal(true)}
                              className="btn-blue rounded-5 p-2 px-4 py-2 ms-4  font-16 text-capitalize"
                            >
                              <i className="fa-solid fa-plus me-2" /> New
                              Automation{" "}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="container-fluid Surveys ">
                      <div className="container-fluid   surveys">
                        <div className="row justify-content-between ">
                          <div className="col-lg-3 px-0">
                            <div
                              className="py-1 font-18 fw-medium blue px-4 h-100 d-flex align-items-center justify-content-center"
                              style={{ borderBottom: "3px solid #1D59F9" }}
                            >
                              Created by you
                            </div>
                          </div>
                          <div className="col-lg-3 p-3 col-12 text-end d-flex align-items-center ">
                            <div className="input-group inputgroupon rounded-5 p-1">
                              <span
                                className="input-group-text border-0 blue"
                                id="basic-addon1"
                                style={{ backgroundColor: "transparent" }}
                              >
                                <i className="fa-solid fa-magnifying-glass" />
                              </span>
                              <input
                                type="text"
                                className="form-control border-0 rounded-5 blue"
                                placeholder="Search Survey..."
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row sms-automation p-3">
                          <div className="col-5">
                            <p className="font-13 inter colorblack mb-0">
                              Name & Rule
                            </p>
                          </div>
                          <div className="col-7">
                            <div className="row">
                              <div className="col-4">
                                <p className="font-13 inter colorblack mb-0">
                                  Status
                                </p>
                              </div>
                              <div className="col-5 align-self-center">
                                <p className="font-13 inter colorblack mb-0">
                                  Last edited
                                </p>
                              </div>
                              <div className="col-3 align-self-center"></div>
                            </div>
                          </div>
                        </div>
                        {/* ======== SMS AUTOMATION CONTANT START ======= */}
                        {isLoading ? (
                          <div className="row justify-content-center not-active-Campaigns rounded-3 text-center mt-3">
                            <div className="col-12">
                              <div className="Campaigns p-4 rounded-3">
                                <Spinner color="dark" />{" "}
                              </div>
                            </div>
                          </div>
                        ) : businessAutomations.length < 1 ? (
                          <div className="row justify-content-center not-active-Campaigns rounded-3 text-center mt-3">
                            <div className="col-12">
                              <div className="Campaigns p-4 rounded-3">
                                <h5 className="fw-bold">
                                  No Active Automations
                                </h5>
                              </div>
                            </div>
                          </div>
                        ) : (
                          businessAutomations.map((index) => {
                            return (
                              <div
                                className="row  p-3"
                                style={{ borderBottom: "1px solid #EDEDED" }}
                              >
                                <div className="col-5 align-self-center ">
                                  <p className="font-15 mb-1 fw-medium inter">
                                    {index.title}
                                  </p>
                                  <p className="font-13 fw-medium inter mb-0 text-dark-gray">
                                    {index.trigger}
                                    <FaArrowRightLong className="mx-1" />
                                    {index.action}{" "}
                                    <FaArrowRightLong className="mx-1" />
                                    {index.content}
                                  </p>
                                </div>
                                <div className="col-7  align-self-center">
                                  <div className="row">
                                    <div className="col-4 align-self-center ">
                                      <p
                                        className="font-15 mb-1 fw-medium inter text-capitalize"
                                        style={{ color: "#04B700" }}
                                      >
                                        <IoMdCheckmark /> {index.status}
                                      </p>
                                    </div>
                                    <div className="col-5 align-self-center">
                                      <p className="font-15 mb-1 text-gray inter">
                                        {index.updated_at}
                                      </p>
                                    </div>
                                    <div className="col-3 align-self-center">
                                      <div className="inter align-self-center ">
                                        <div className="dropdown text-end">
                                          <img
                                            src={toggle}
                                            width={40}
                                            height={40}
                                            alt="w8"
                                            className=" dropdown-toggle"
                                            role="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                          />

                                          <ul className="dropdown-menu">
                                            <li>
                                              <p
                                                className="dropdown-item cursor-pointer"
                                                onClick={() => {
                                                  setShowAutomationData(index);
                                                  setShowAutomationDataModal(
                                                    true
                                                  );
                                                }}
                                              >
                                                View
                                              </p>
                                            </li>
                                            <li>
                                              <p className="dropdown-item cursor-pointer">
                                                Delete
                                              </p>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        )}

                        {/* ======== SMS AUTOMATION CONTANT END ======= */}

                        <div className="col-12 text-center my-5 py-5 ">
                          <p className="font-12 font-500 inter colorgray mb-0">
                            No current SMS automations
                          </p>
                          <p className="font-12 font-500 inter colorgray mb-0">
                            Default SMS - Active{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="pills-tabThree"
                    role="tabpanel"
                    aria-labelledby="pills-tabThree-tab"
                    tabIndex={0}
                  >
                    ...
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-tabFour"
                    role="tabpanel"
                    aria-labelledby="pills-tabFour-tab"
                    tabIndex={0}
                  >
                    ...
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        centered
        className="survey-page modals_scroll business_admin_main"
      >
        <Modal.Body style={{ maxHeight: "85vh", overflowY: "auto" }}>
          <form
            className="row justify-content-center rounded-4 px-2 pb-3"
            onSubmit={handleCreateAutomation}
          >
            <div className="d-flex flex-row justify-content-between">
              <div></div>
              <h6 className="font-15 inter colorPrimary text-center">
                New Automation
              </h6>
              <IoMdClose
                onClick={() => setShowModal(false)}
                size={20}
                color="#000"
                className="cursor-pointer"
              />
            </div>

            <div className="col-lg-11 col-md-11 col-12">
              <div className="row justify-content-center">
                <div className="col-12">
                  <p className=" text-dark mb-1 text-start font-13 fw-medium ">
                    Title
                  </p>
                  <input
                    type="text"
                    value={automationTitle}
                    onChange={(e) => setAutomationTitle(e.target.value)}
                    className="form-control  input-field text-center font-13 px-3 rounded-5"
                    required
                    placeholder="E.g. Notify - Custom"
                    style={{ padding: "12px" }}
                  />
                </div>
                <div className="col-12">
                  <Accordion className="sms_automation_accordion rounded-5 mt-3">
                    <AccordionSummary
                      expandIcon={<FaAngleDown className="colorPrimary" />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <div className="d-flex align-items-center  justify-content-between w-100 inter">
                        <div>
                          <p className="mb-0 font-12 fw-medium ">Trigger</p>
                          <p className="mb-0 font-15">
                            When:{" "}
                            <span className="colorPrimary">{trigger}</span>{" "}
                          </p>
                        </div>
                        <p className="mb-0 font-10 fw-medium colorPrimary">
                          Edit
                        </p>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{ borderTop: "1.5px solid #D9D9D9" }}
                    >
                      <div>
                        <p className=" text-dark mb-1 text-start font-13 fw-medium ">
                          Select a trigger
                        </p>
                        <FormControl className="radio-form-control">
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={trigger}
                            onChange={(e) => setTrigger(e.target.value)}
                            name="radio-buttons-group"
                          >
                            <FormControlLabel
                              value="Notify is clicked"
                              control={<Radio style={{ color: "#3461FD" }} />}
                              label="Notify is clicked"
                            />
                            <FormControlLabel
                              value="Notify & Complete is clicked"
                              control={<Radio style={{ color: "#3461FD" }} />}
                              label="Notify & Complete is clicked"
                            />
                            <FormControlLabel
                              value="Complete is clicked"
                              control={<Radio style={{ color: "#3461FD" }} />}
                              label="Complete is clicked"
                            />
                            <FormControlLabel
                              value="Customer enrols in Loyalty Program"
                              control={<Radio style={{ color: "#3461FD" }} />}
                              label="Customer enrols in Loyalty Program"
                            />
                            <FormControlLabel
                              value="Customer says Yes to Marketing Campaign"
                              control={<Radio style={{ color: "#3461FD" }} />}
                              label="Customer says Yes to Marketing Campaign"
                            />
                            <FormControlLabel
                              value="Customer has a loyalty reward to redeem"
                              control={<Radio style={{ color: "#3461FD" }} />}
                              label="Customer has a loyalty reward to redeem"
                            />
                            <FormControlLabel
                              value="Customer has a Marketing Campaign to redeem"
                              control={<Radio style={{ color: "#3461FD" }} />}
                              label="Customer has a Marketing Campaign to redeem"
                            />
                            <FormControlLabel
                              value="Customer completes survey"
                              control={<Radio style={{ color: "#3461FD" }} />}
                              label="Customer completes survey"
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className="sms_automation_accordion rounded-5 mt-3">
                    <AccordionSummary
                      expandIcon={<FaAngleDown className="colorPrimary" />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <div className="d-flex align-items-center  justify-content-between w-100 inter">
                        <div>
                          <p className="mb-0 font-12 fw-medium ">Action</p>
                          <p className="mb-0 font-15">
                            Do this:{" "}
                            <span className="colorPrimary">{action}</span>{" "}
                          </p>
                        </div>
                        <p className="mb-0 font-10 fw-medium colorPrimary">
                          Edit
                        </p>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{ borderTop: "1.5px solid #D9D9D9" }}
                    >
                      <div>
                        <p className=" text-dark mb-1 text-start font-13 fw-medium ">
                          Select an action{" "}
                        </p>
                        <FormControl className="radio-form-control">
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={action}
                            onChange={(e) => setAction(e.target.value)}
                            name="radio-buttons-group"
                          >
                            <FormControlLabel
                              value="Send an SMS"
                              control={<Radio style={{ color: "#3461FD" }} />}
                              label="Send an SMS"
                            />
                            <FormControlLabel
                              value="Send a web-based notification"
                              control={<Radio style={{ color: "#3461FD" }} />}
                              label="Send a web-based notification"
                            />
                            <FormControlLabel
                              value="Do nothing"
                              control={<Radio style={{ color: "#3461FD" }} />}
                              label="Do nothing"
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className="sms_automation_accordion rounded-5 mt-3">
                    <AccordionSummary
                      expandIcon={<FaAngleDown className="colorPrimary" />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <div className="d-flex align-items-center  justify-content-between w-100 inter">
                        <div>
                          <p className="mb-0 font-12 fw-medium ">Content</p>
                          <p className="mb-0 font-15">
                            Saying this:{" "}
                            <span className="colorPrimary">{content}</span>{" "}
                          </p>
                        </div>
                        <p className="mb-0 font-10 fw-medium colorPrimary">
                          Edit
                        </p>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{ borderTop: "1.5px solid #D9D9D9" }}
                    >
                      <div>
                        <p className=" text-dark mb-1 text-start font-13 fw-medium ">
                          Select content{" "}
                        </p>
                        <FormControl className="radio-form-control">
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={content}
                            onChange={(e) => setContent(e.target.value)}
                            name="radio-buttons-group"
                          >
                            <FormControlLabel
                              value={`Your order from ${userDetail?.business_name} is ready for pick-up.`}
                              control={<Radio style={{ color: "#3461FD" }} />}
                              label={`Your order from ${userDetail?.business_name} is ready for pick-up.`}
                            />
                            <FormControlLabel
                              value={`Your order from ${userDetail?.business_name} has been completed`}
                              control={<Radio style={{ color: "#3461FD" }} />}
                              label={`Your order from ${userDetail?.business_name} has been completed.`}
                            />
                            <FormControlLabel
                              value={`Your order from ${userDetail?.business_name} will be ready for pick-up in 5 minutes`}
                              control={<Radio style={{ color: "#3461FD" }} />}
                              label={`Your order from ${userDetail?.business_name} will be ready for pick-up in 5 minutes`}
                            />
                            <FormControlLabel
                              value={`Thank you for leaving feedback! ${userDetail?.business_name}`}
                              control={<Radio style={{ color: "#3461FD" }} />}
                              label={`Thank you for leaving feedback! ${userDetail?.business_name}`}
                            />
                            <FormControlLabel
                              value={`Custom message`}
                              control={<Radio style={{ color: "#3461FD" }} />}
                              label={`Custom message`}
                            />
                          </RadioGroup>
                        </FormControl>
                        {content == "Custom message" && (
                          <div className="ps-4 d-flex align-items-end ">
                            <textarea
                              required
                              cols="25"
                              className="font-12 rounded-4"
                              value={automationDetail}
                              onChange={(e) =>
                                setAutomationDetail(e.target.value)
                              }
                              rows="3"
                            ></textarea>
                          </div>
                        )}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
              <div className="d-flex justify-content-center mt-3">
                <div className="ms-2">
                  <button
                    type="button"
                    className="btn-blueTwo rounded-5 p-2 px-3 py-2 fw-medium font-14 text-capitalize"
                    onClick={handleCreateAutomation}
                  >
                    {isLoading ? (
                      <Spinner size={"sm"} color={"#ffffff"} />
                    ) : (
                      "Complete"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <ToastContainer />
      </Modal>
      <Modal
        show={showAutomationDataModal}
        centered
        className="survey-page modals_scroll business_admin_main"
      >
        <Modal.Body style={{ maxHeight: "85vh", overflowY: "auto" }}>
          <form
            className="row justify-content-center rounded-4 px-2 pb-3"
            onSubmit={() => {
              return false;
            }}
          >
            <div className="d-flex flex-row justify-content-between">
              <div></div>
              <h6 className="font-15 inter colorPrimary text-center">
                New Automation
              </h6>
              <IoMdClose
                onClick={() => {
                  setShowAutomationDataModal(false);
                  setShowAutomationData(null);
                }}
                size={20}
                color="#000"
                className="cursor-pointer"
              />
            </div>

            <div className="col-lg-11 col-md-11 col-12">
              <div className="row justify-content-center">
                <div className="col-12">
                  <p className=" text-dark mb-1 text-start font-13 fw-medium ">
                    Title
                  </p>
                  <input
                    type="text"
                    value={showAutomationData?.title}
                    onChange={(e) => false}
                    className="form-control  input-field text-center font-13 px-3 rounded-5"
                    required
                    placeholder="E.g. Notify - Custom"
                    style={{ padding: "12px" }}
                  />
                </div>
                <div className="col-12">
                  <Accordion className="sms_automation_accordion rounded-5 mt-3">
                    <AccordionSummary
                      expandIcon={<FaAngleDown className="colorPrimary" />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <div className="d-flex align-items-center  justify-content-between w-100 inter">
                        <div>
                          <p className="mb-0 font-12 fw-medium ">Trigger</p>
                          <p className="mb-0 font-15">
                            When:{" "}
                            <span className="colorPrimary">
                              {showAutomationData?.trigger}
                            </span>{" "}
                          </p>
                        </div>
                        <p className="mb-0 font-10 fw-medium colorPrimary">
                          Edit
                        </p>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{ borderTop: "1.5px solid #D9D9D9" }}
                    >
                      <div>
                        <p className=" text-dark mb-1 text-start font-13 fw-medium ">
                          Select a trigger
                        </p>
                        <FormControl className="radio-form-control">
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={showAutomationData?.trigger}
                            onChange={(e) => false}
                            name="radio-buttons-group"
                          >
                            <FormControlLabel
                              value="Notify is clicked"
                              control={<Radio style={{ color: "#3461FD" }} />}
                              label="Notify is clicked"
                            />
                            <FormControlLabel
                              value="Notify & Complete is clicked"
                              control={<Radio style={{ color: "#3461FD" }} />}
                              label="Notify & Complete is clicked"
                            />
                            <FormControlLabel
                              value="Complete is clicked"
                              control={<Radio style={{ color: "#3461FD" }} />}
                              label="Complete is clicked"
                            />
                            <FormControlLabel
                              value="Customer enrols in Loyalty Program"
                              control={<Radio style={{ color: "#3461FD" }} />}
                              label="Customer enrols in Loyalty Program"
                            />
                            <FormControlLabel
                              value="Customer says Yes to Marketing Campaign"
                              control={<Radio style={{ color: "#3461FD" }} />}
                              label="Customer says Yes to Marketing Campaign"
                            />
                            <FormControlLabel
                              value="Customer has a loyalty reward to redeem"
                              control={<Radio style={{ color: "#3461FD" }} />}
                              label="Customer has a loyalty reward to redeem"
                            />
                            <FormControlLabel
                              value="Customer has a Marketing Campaign to redeem"
                              control={<Radio style={{ color: "#3461FD" }} />}
                              label="Customer has a Marketing Campaign to redeem"
                            />
                            <FormControlLabel
                              value="Customer completes survey"
                              control={<Radio style={{ color: "#3461FD" }} />}
                              label="Customer completes survey"
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className="sms_automation_accordion rounded-5 mt-3">
                    <AccordionSummary
                      expandIcon={<FaAngleDown className="colorPrimary" />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <div className="d-flex align-items-center  justify-content-between w-100 inter">
                        <div>
                          <p className="mb-0 font-12 fw-medium ">Action</p>
                          <p className="mb-0 font-15">
                            Do this:{" "}
                            <span className="colorPrimary">
                              {showAutomationData?.action}
                            </span>{" "}
                          </p>
                        </div>
                        <p className="mb-0 font-10 fw-medium colorPrimary">
                          Edit
                        </p>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{ borderTop: "1.5px solid #D9D9D9" }}
                    >
                      <div>
                        <p className=" text-dark mb-1 text-start font-13 fw-medium ">
                          Select an action{" "}
                        </p>
                        <FormControl className="radio-form-control">
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={showAutomationData?.action}
                            onChange={(e) => false}
                            name="radio-buttons-group"
                          >
                            <FormControlLabel
                              value="Send an SMS"
                              control={<Radio style={{ color: "#3461FD" }} />}
                              label="Send an SMS"
                            />
                            <FormControlLabel
                              value="Send a web-based notification"
                              control={<Radio style={{ color: "#3461FD" }} />}
                              label="Send a web-based notification"
                            />
                            <FormControlLabel
                              value="Do nothing"
                              control={<Radio style={{ color: "#3461FD" }} />}
                              label="Do nothing"
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className="sms_automation_accordion rounded-5 mt-3">
                    <AccordionSummary
                      expandIcon={<FaAngleDown className="colorPrimary" />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <div className="d-flex align-items-center  justify-content-between w-100 inter">
                        <div>
                          <p className="mb-0 font-12 fw-medium ">Content</p>
                          <p className="mb-0 font-15">
                            Saying this:{" "}
                            <span className="colorPrimary">
                              {showAutomationData?.content}
                            </span>{" "}
                          </p>
                        </div>
                        <p className="mb-0 font-10 fw-medium colorPrimary">
                          Edit
                        </p>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{ borderTop: "1.5px solid #D9D9D9" }}
                    >
                      <div>
                        <p className=" text-dark mb-1 text-start font-13 fw-medium ">
                          Select content{" "}
                        </p>
                        <FormControl className="radio-form-control">
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={showAutomationData?.content}
                            onChange={(e) => false}
                            name="radio-buttons-group"
                          >
                            <FormControlLabel
                              value={`Your order from ${userDetail?.business_name} is ready for pick-up.`}
                              control={<Radio style={{ color: "#3461FD" }} />}
                              label={`Your order from ${userDetail?.business_name} is ready for pick-up.`}
                            />
                            <FormControlLabel
                              value={`Your order from ${userDetail?.business_name} has been completed`}
                              control={<Radio style={{ color: "#3461FD" }} />}
                              label={`Your order from ${userDetail?.business_name} has been completed.`}
                            />
                            <FormControlLabel
                              value={`Your order from ${userDetail?.business_name} will be ready for pick-up in 5 minutes`}
                              control={<Radio style={{ color: "#3461FD" }} />}
                              label={`Your order from ${userDetail?.business_name} will be ready for pick-up in 5 minutes`}
                            />
                            <FormControlLabel
                              value={`Thank you for leaving feedback! ${userDetail?.business_name}`}
                              control={<Radio style={{ color: "#3461FD" }} />}
                              label={`Thank you for leaving feedback! ${userDetail?.business_name}`}
                            />
                            <FormControlLabel
                              value={`Custom message`}
                              control={<Radio style={{ color: "#3461FD" }} />}
                              label={`Custom message`}
                            />
                          </RadioGroup>
                        </FormControl>
                        {content == "Custom message" && (
                          <div className="ps-4 d-flex align-items-end ">
                            <textarea
                              required
                              cols="25"
                              className="font-12 rounded-4"
                              value={showAutomationData?.content}
                              onChange={(e) => false}
                              rows="3"
                            ></textarea>
                          </div>
                        )}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <ToastContainer />
      </Modal>
      {/* cover image   */}
      <Modal
        show={editImgModal}
        className="business_admin_main"
        centered
        onHide={() => setEditImgModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {image && (
              <>
                <div className="imag_crop_shadow d-flex justify-content-center ">
                  <AvatarEditor
                    width={300}
                    height={150}
                    ref={previewRef}
                    border={30}
                    borderRadius={14}
                    color={[80, 76, 76, 0.6]}
                    scale={scale}
                    rotate={rotate}
                    image={URL.createObjectURL(image)}
                  />
                </div>
                <div className="my-3 flex gap-2 items-center justify-center">
                  <button
                    role="button"
                    className="btn-blue rounded-sm  w-5 h-5 flex justify-center items-center"
                    onClick={() => setScale(scale - 0.1)}
                  >
                    <FiZoomOut />
                  </button>
                  <button
                    role="button"
                    className="btn-blue rounded-sm  w-5 h-5 flex justify-center items-center"
                    onClick={() => setScale(scale + 0.1)}
                  >
                    <FiZoomIn />
                  </button>

                  <button
                    role="button"
                    className="btn-blue rounded-sm w-5 h-5 flex justify-center items-center"
                    onClick={() => setRotate(rotate - 90)}
                  >
                    <FaArrowRotateLeft />
                  </button>
                  <button
                    role="button"
                    className="btn-blue rounded-sm  w-5 h-5 flex justify-center items-center"
                    onClick={() => setRotate(rotate + 90)}
                  >
                    <FaArrowRotateRight />
                  </button>
                </div>
              </>
            )}

            <div className=" inputgroup1 p-3 mt-1 text-center ">
              <label htmlFor="file" className="h-100 w-100 cursor-pointer">
                <div className="px-3 py-3 mb-3 ">
                  <div className="flex justify-center">
                    <img src={upload} width={60} alt />
                  </div>
                  <h6 className="font-15 font-500 inter colorblack mt-2 point">
                    Drag &amp; drop files or{" "}
                    <span className="colorblue text-decoration-underline">
                      Browse
                    </span>
                  </h6>
                  <p className="mb-0 mt-2 font-12 colorgray inter point">
                    Supported formates: <br />
                    JPEG, PNG
                  </p>
                </div>
              </label>
            </div>
            <input
              type="file"
              id="file"
              onChange={(e) => setImage(e.target.files[0])}
              className="d-none"
              accept="image/*"
            />
          </div>
        </Modal.Body>
        {image && (
          <Modal.Footer>
            <button
              role="button"
              className="btn-blue rounded-2 p-2 m-1"
              onClick={handlePreview}
            >
              Upload
            </button>
          </Modal.Footer>
        )}
      </Modal>
      {/* --- logo crop */}
      <Modal
        show={editImgModalLogo}
        className="business_admin_main"
        centered
        onHide={() => setEditImgModalLogo(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {imageLogo && (
              <>
                <div className="imag_crop_shadow d-flex justify-content-center ">
                  <AvatarEditor
                    width={300}
                    height={150}
                    ref={previewRefLogo}
                    border={30}
                    borderRadius={14}
                    color={[80, 76, 76, 0.6]}
                    scale={scaleLogo}
                    rotate={rotateLogo}
                    image={URL.createObjectURL(imageLogo)}
                  />
                </div>
                <div className="my-3 flex gap-2 items-center justify-center">
                  <button
                    role="button"
                    className="btn-blue rounded-sm  w-5 h-5 flex justify-center items-center"
                    onClick={() => setScaleLogo(scaleLogo - 0.1)}
                  >
                    <FiZoomOut />
                  </button>
                  <button
                    role="button"
                    className="btn-blue rounded-sm  w-5 h-5 flex justify-center items-center"
                    onClick={() => setScaleLogo(scaleLogo + 0.1)}
                  >
                    <FiZoomIn />
                  </button>

                  <button
                    role="button"
                    className="btn-blue rounded-sm w-5 h-5 flex justify-center items-center"
                    onClick={() => setRotateLogo(rotateLogo - 90)}
                  >
                    <FaArrowRotateLeft />
                  </button>
                  <button
                    role="button"
                    className="btn-blue rounded-sm  w-5 h-5 flex justify-center items-center"
                    onClick={() => setRotateLogo(rotateLogo + 90)}
                  >
                    <FaArrowRotateRight />
                  </button>
                </div>
              </>
            )}

            <div className=" inputgroup1 p-3 mt-1 text-center ">
              <label htmlFor="fileLogo" className="h-100 w-100 cursor-pointer">
                <div className="px-3 py-3 mb-3 ">
                  <div className="flex justify-center">
                    <img src={upload} width={60} alt />
                  </div>
                  <h6 className="font-15 font-500 inter colorblack mt-2 point">
                    Drag &amp; drop files or{" "}
                    <span className="colorblue text-decoration-underline">
                      Browse
                    </span>
                  </h6>
                  <p className="mb-0 mt-2 font-12 colorgray inter point">
                    Supported formates: <br />
                    JPEG, PNG
                  </p>
                </div>
              </label>
            </div>
            <input
              type="file"
              id="fileLogo"
              onChange={(e) => setImageLogo(e.target.files[0])}
              className="d-none"
              accept="image/*"
            />
          </div>
        </Modal.Body>
        {imageLogo && (
          <Modal.Footer>
            <button
              role="button"
              className="btn-blue rounded-2 p-2 m-1"
              onClick={handlePreviewLogo}
            >
              Upload
            </button>
          </Modal.Footer>
        )}
      </Modal>
      <Modal
        isOpen={isIframeVisible}
        onRequestClose={closeModal}
        contentLabel="Iframe Modal"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            height: "80%",
            backgroundColor: "red",
          },
        }}
      >
        <button onClick={closeModal}>Close</button>
        {iframeUrl && (
          <iframe
            src={iframeUrl}
            title="Dynamic Iframe"
            width="100%"
            height="100%"
          />
        )}
      </Modal>
    </section>
  );
};

export default Setting;
